/* ===================== SERVICE DETAILS ======================*/
.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar-widget:last-child {
  margin-bottom: 0;
}

.service-menu {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li {
  margin-bottom: 5px;
}

.service-menu li:last-child {
  margin-bottom: 0;
}

.service-menu li a {
  display: block;
  padding: 10px 20px;
  background: #fff none repeat scroll 0 0;
  color: #001238;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.service-menu li a span {
  margin-left: 5px;
}

.service-menu li.active a,
.service-menu li a:hover {
  color: #e79e3e;
  padding-left: 25px;
}

.brochures {
  text-align: center;
  background: #020202 none repeat scroll 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 25px auto 0;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.brochures:after {
  position: absolute;
  content: "";
  width: 62%;
  height: 100%;
  background: #e79e3e none repeat scroll 0 0;
  right: -36px;
  z-index: -1;
  -webkit-transform: skewX(40deg);
  transform: skewX(40deg);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.brochures a {
  display: inline-block;
  text-transform: uppercase;
  padding: 15px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  width: 47%;
}

.brochures a i {
  margin-right: 5px;
}

.brochures:hover {
  background: #e79e3e none repeat scroll 0 0;
}

.brochures:hover:after {
  background: #020202 none repeat scroll 0 0;
}

.service-page-banner {
  background: url(../../img/find-box-bg.png) no-repeat scroll 0 0 / cover;
  padding: 30px;
  text-align: center;
  color: #fff;
}

.service-page-banner > h3 {
  font-size: 28px !important;
  color: #fff !important;
  letter-spacing: 1px !important;
  margin-bottom: 15px !important;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  line-height: 40px !important;
}

.service-page-banner a {
  margin-bottom: 0;
  border-color: #fff;
  margin-top: 20px;
}

.service-page-banner a:before,
.service-page-banner a:after {
  content: "";
  display: block;
  position: absolute;
  border-color: #fff;
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.service-page-banner a:hover {
  color: #e79e3e;
  background-color: #fff;
  border-color: #fff;
}

.service-page-banner a:hover:before,
.service-page-banner a:hover:after {
  border-color: #fff;
}

.service-details-right h3,
.sidebar-widget h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.service-details-right p {
  margin: 0 0 10px;
}

.pad-right-sm {
  padding-right: 7px;
}

.pad-left-sm {
  padding-left: 7px;
}

.service-details-image {
  margin-top: 10px;
}

.service-details-list ul {
  width: 50%;
  float: left;
  margin-top: 20px;
}

.service-details-list li {
  margin: 5px 0;
}

.service-details-list li svg {
  fill: #e79e3e;
  margin-right: 5px;
}

.service-accordion {
  margin-top: 20px;
}

.service-accordion h3,
.sidebar-widget h3 {
  font-size: 20px;
}

.Tags-catagory {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.Tags-catagory li {
  display: inline-block;
  border: medium none !important;
  padding: 0 !important;
  margin: 2px 0;
}

.Tags-catagory a {
  color: #001238;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  padding: 5px 15px;
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
}

.Tags-catagory a:hover {
  color: #e79e3e;
}

.single_faq_accordian {
  margin-top: 10px;
}

.faq_accordian_header > button {
  display: block;
  text-align: left;
  padding: 20px 20px 20px 85px;
  background: #020202 none repeat scroll 0 0;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  border: 0px solid rgba(0, 0, 0, 0.08);
  border-radius: 0;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.faq_accordian_header > button:hover,
.faq_accordian_header > button:focus {
  background: #020202 none repeat scroll 0 0;
  color: #fff;
  outline: medium none;
}

.faq_accordian_header > button:after {
  position: absolute;
  left: 20px;
  color: #fff;
  font-size: 18px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.faq_accordian_header > button:before {
  position: absolute;
  content: "";
  top: 0;
  width: 12%;
  height: 100%;
  left: -26px;
  background: #e79e3e;
  -webkit-transform: skew(-30deg);
  transform: skew(-30deg);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.faq_accordian_header > button.collapsed:before {
  position: absolute;
  content: "";
  top: 0;
  width: 12%;
  height: 100%;
  left: -26px;
  background: #e79e3e;
  -webkit-transform: skew(30deg);
  transform: skew(30deg);
}

.faq_accordian_body {
  padding: 10px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #020202;
  box-shadow: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .service-details-right {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .service-details-right h3,
  .sidebar-widget h3 {
    font-size: 22px !important;
    line-height: 40px !important;
  }
  .pad-right-sm {
    padding-right: 15px;
  }
  .pad-left-sm {
    padding-left: 15px;
  }
  .service-details-list ul {
    width: 100% !important;
    float: none !important;
    margin: 0 !important;
  }
  .service-details-list.clearfix {
    margin-top: 30px;
  }
  .faq_accordian_header > button.collapsed:before {
    width: 30%;
  }
  .faq_accordian_header > button:before {
    width: 30%;
  }
  .service-details-right {
    margin-top: 30px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .service-details-right {
    margin-top: 30px !important;
  }
  .service-details-right h3,
  .sidebar-widget h3 {
    font-size: 22px;
    line-height: 40px;
  }
  .pad-right-sm {
    padding-right: 15px;
  }
  .pad-left-sm {
    padding-left: 15px;
  }
  .service-details-list ul {
    width: 100%;
    float: none;
    margin: 0;
  }
  .service-details-list.clearfix {
    margin-top: 30px;
  }
  .faq_accordian_header > button.collapsed:before {
    width: 20%;
  }
  .faq_accordian_header > button:before {
    width: 20%;
  }
}
