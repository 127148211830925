/* ===================== SERVICE ======================*/
.gauto-service-area {
  background: #fbfbfd none repeat scroll 0 0;
  padding-bottom: 105px;
}

.service-page-area {
  padding-bottom: 70px;
}

.single-service {
  padding: 50px 30px;
  background: #fff none repeat scroll 0 0;
  border: 2px solid #fff;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  position: relative;
  box-shadow: 0px 3px 14px 0px rgba(0, 11, 40, 0.06);
  margin: 30px 5px 5px 5px;
}
.service-page-area .single-service {
  margin: 30px 0 0 0;
}

.service-number {
  font-size: 55px;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 60px;
  height: 55px;
  font-weight: 500;
  line-height: 55px;
  color: rgba(35, 61, 92, 0.07);
  border-radius: 0 0 0 10px;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service-icon {
  width: 60px;
}

.service-text {
  margin-top: 25px;
}

.service-text h3 {
  font-size: 21px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.service-text a {
  color: #001238;
}

.service-text a:hover,
.service-text h3:hover {
  color: #e79e3e;
}
.service-slider .slide {
  padding: 0 15px;
}
.service-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}
.service-slider .slick-dots {
  margin-top: 30px;
  position: inherit;
  bottom: 0;
}
.service-slider .slick-dots li button:before {
  display: none;
}
.service-slider .slick-dots li button {
  font-size: 0px;
  line-height: 0;
  display: block;
  width: 6px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #020202;
  margin: auto;
}
.service-slider .slick-dots li.slick-active button {
  background: #e79e3e;
}
.service-slider .slick-dots li {
  width: auto;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .single-service {
    padding: 25px;
  }
  .service-text h3 {
    font-size: 19px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-service {
    margin: 30px 0 0 0;
    border: 0px solid #fff;
    padding: 30px 15px;
  }
  .service-text h3 {
    font-size: 17px;
    letter-spacing: 0;
  }
  .service-icon {
    width: 50px;
  }
  .service-menu {
    padding: 20px;
  }
}
@media (max-width: 767px) {
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-service {
    padding: 20px;
  }
  .service-text h3 {
    font-size: 18px;
    letter-spacing: 0;
  }
}
