/* ===================== CAR BOOKING ======================*/
@import "/node_modules/@syncfusion/ej2-base/styles/material.css";
@import "/node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "/node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "/node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "/node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "~react-image-gallery/styles/css/image-gallery.css";
.gauto-car-booking {
  background: #fbfbfb none repeat scroll 0 0;
}

.rental-tag {
  display: inline-block;
  padding: 5px 15px;
  line-height: 20px;
  text-transform: uppercase;
  background: #e79e3e;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.gauto-main-slide2 {

  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 640px;
}

.car-booking-right h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.car-booking-right h4 {
  color: #020202;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 0px;
  display: inline-block;
}

.car-booking-right h4 span {
  text-transform: capitalize;
  color: #e79e3e;
  font-size: 16px;
}

.price-rent {
  display: inline-block;
  margin-right: 15px;
}

.car-rating {
  display: inline-block;
}

.car-rating ul {
  display: inline-block;
}

.car-rating ul li {
  display: inline-block;
  margin-right: 1px;
  color: #ffcd00;
}

.car-rating p {
  display: inline-block;
  margin-left: 5px;
  color: #001238;
  text-transform: capitalize;
}

.price-rating {
  margin-bottom: 20px;
}

.car-features ul {
  width: 32%;
  float: left;
  margin-top: 20px;
}

.car-features ul li {
  margin: 5px 0;
}

.car-features ul li svg {
  margin-right: 5px;
  fill: #e79e3e;
}

.single-booking h3,
.booking-right h3 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0ff;
}

.single-booking form label {
  display: block;
  color: #001238;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}

.single-booking form p {
  margin-top: 15px;
}

.single-booking form p input,
.single-booking form p select {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-booking form p textarea {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 120px;
  color: #111;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-booking form p input:focus,
.single-booking form p select:focus {
  border: 2px solid #e79e3e;
}

.single-booking {
  margin-bottom: 30px;
}

.single-booking:last-child {
  margin-bottom: 0px;
}

.gauto-payment .payment {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.gauto-payment .payment input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.gauto-payment .payment label {
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  margin: 10px auto;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  color: #020202;
  font-weight: 500;
}

.gauto-payment .payment .check {
  display: block;
  position: absolute;
  border: 3px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 14px;
  left: 0;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.gauto-payment .payment .check:before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 8px;
  width: 8px;
  top: 3px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .check {
  border: 3px solid #e79e3e;
}

input[type="radio"]:checked ~ .check:before {
  background: #e79e3e;
}

input[type="radio"]:checked ~ label {
  color: #e79e3e;
}

.payment img {
  float: right;
  margin-top: 15px;
}

.action-btn {
  text-align: right;
}

.action-btn a.gauto-btn {
  color: #e79e3e;
  margin: 30px 0 0 0;
}

.action-btn a.gauto-btn:hover {
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
  .car-booking-image img {
    width: 100%;
  }
  .car-booking-right {
    margin-top: 30px;
  }
  .booking-right {
    margin-top: 30px;
  }
  .gauto-main-slide2 {
    height: 460px;
  }

}

@media (max-width: 767px) {
  .car-booking-right {
    margin-top: 30px;
  }
  .car-booking-right h3,
  .product-details-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .car-features ul {
    width: 50%;
  }
  .booking-right {
    margin-top: 30px;
  }
  .gauto-main-slide2 {
    height: 380px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .car-booking-right {
    margin-top: 30px;
  }
  .car-booking-right h3,
  .product-details-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .car-features ul {
    width: 33%;
  }
  .booking-right {
    margin-top: 30px;
  }

  .gauto-main-slide2 {
    height: 380px;
  }
}
