/* ===================== BLOG SINGLE ======================*/
.single-blog-page .blog-text {
  padding: 30px 0 0 0;
}

.single-blog-page .blog-text p {
  margin: 0 0 10px;
}

.single-blog-page .single-blog {
  border: 0px solid #f0f0ff;
}

.blog-content-img {
  margin-bottom: 15px;
}

.single-blog-page blockquote {
  margin: 30px 0;
  position: relative;
}

.single-blog-page blockquote .quote-inner {
  border-left: 2px solid #ddd;
  margin-left: 25px;
  padding-left: 60px;
  position: relative;
}

.single-blog-page blockquote .quote-icon {
  background-color: #ffffff;
  border: 2px solid #e9f2f7;
  border-radius: 50%;
  color: #d2dae0;
  font-size: 16px;
  height: 35px;
  left: -18px;
  line-height: 32px;
  margin-top: -17px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 35px;
  padding: 7px;
}

.single-blog-page blockquote .quote-text {
  color: #001238;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  position: relative;
}

.gauto-comment-list,
.gauto-leave-comment {
  margin-top: 50px;
}

.gauto-comment-list h2,
.gauto-leave-comment h2 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.single-comment-box {
  margin-bottom: 10px;
  position: relative;
  -webkit-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
}

.main-comment {
  font-size: 14px;
  padding: 30px 0 0 90px;
  position: relative;
}

.author-image {
  border-radius: 50%;
  left: 0;
  margin-bottom: 20px;
  position: absolute;
  top: 30px;
}

.author-image img {
  display: block;
  width: 70px;
  border-radius: 50%;
  border: 6px solid #e6e6e6;
}

.comment-info h4 {
  font-size: 16px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.comment-info a {
  color: #001238;
  font-style: italic;
}

.comment-info a:hover {
  color: #e79e3e;
}

.comment-info a i {
  margin-right: 5px;
}

.single-comment-box.reply-comment {
  padding-left: 100px;
}

.gauto-leave-comment form input {
  width: 45%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  margin: 10px 15px 10px 0px;
}

.gauto-leave-comment form textarea {
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  margin: 10px 0;
  color: #111;
  width: 93%;
  height: 175px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.gauto-leave-comment form input:focus,
.gauto-leave-comment form textarea:focus {
  border: 2px solid #e79e3e;
}

.gauto-leave-comment .gauto-theme-btn {
  width: auto;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .single-comment-box.reply-comment {
    padding-left: 30px;
  }
  .gauto-leave-comment form input {
    width: 100%;
    margin-right: 0;
  }
  .gauto-leave-comment form textarea {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .blog-text {
    padding: 15px 10px;
  }
  .blog-text ul li {
    margin-right: 5px;
  }
  .blog-page-left .blog-text h3 {
    font-size: 22px;
    line-height: 36px;
  }
  .blog-page-right {
    margin-top: 30px;
  }
  .single-comment-box.reply-comment {
    padding-left: 30px;
  }
  .gauto-leave-comment form input {
    width: 100%;
    margin-right: 0;
  }
  .gauto-leave-comment form textarea {
    width: 100%;
  }
}
