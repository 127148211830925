@import url(/node_modules/@syncfusion/ej2-base/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-buttons/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-inputs/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-popups/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-react-calendars/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-base/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-buttons/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-inputs/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-popups/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-react-calendars/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-base/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-buttons/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-inputs/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-popups/styles/material.css);
@import url(/node_modules/@syncfusion/ej2-react-calendars/styles/material.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i&display=swap);
/* Responsive */
@media (max-width: 991px) {
  .responsiveMenu {
    display: block !important;
  }
  .spinner-master * {
    transition: all 0.3s;
    box-sizing: border-box;
  }
  .spinner-spin {
    text-indent: -99999px;
    width: 22px;
  }
  .spinner-master {
    position: relative;
    width: 46px;
    display: flex;
    align-items: center;
    background: transparent;
    height: 46px;
    justify-content: center;
    z-index: 9999;
  }
  .spinner:nth-child(2n) {
    width: 70% !important;
  }
  .spinner-master label {
    cursor: pointer;
    height: auto;
    width: 100%;
    padding: 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    top: -13px;
    position: relative;
  }
  .spinner-master .spinner {
    height: 2px;
    width: 100%;
    background-color: #ffffff;
  }
  .spinner-master .horizontal {
    margin-top: 6px;
  }
  .spinner-master .diagonal.part-2 {
    margin-top: 6px;
  }
  .spinner-master.active .spinner-spin > .horizontal {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  .spinner-master.active .spinner-spin > .diagonal.part-1 {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-top: 3px;
  }
  .spinner-master.active .spinner-spin > .diagonal.part-2 {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: -10px;
  }
  nav#mobileMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: block;
    z-index: 9999;
  }
  nav#mobileMenu {
    overflow: hidden;
    max-height: 0;
    background-color: #000;
    transition: all 0.4s ease 0s;
  }
  nav#mobileMenu.active,
  nav#mobileMenu > ul ul.show {
    max-height: 55em;
    overflow: auto;
    padding: 10px 20px;
  }
  nav#mobileMenu > ul ul.show {
    padding: 0;
  }
  nav#mobileMenu > ul > li {
    display: block !important;
    margin: 0 !important;
  }
  nav#mobileMenu > ul > li > a,
  nav#mobileMenu > ul > li > p {
    display: block;
    position: relative;
    padding: 10px 0;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }
  nav#mobileMenu > ul > li:last-child > a {
    border-bottom: 0px;
  }
  nav#mobileMenu li.has-submenu > a:before,
  .mainmenu ul > li.active a:before {
    display: none;
  }
  .megamenu.active {
    padding: 15px 0 !important;
  }
  .sub-menu:before {
    height: 90% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }
  .sub-menu:after {
    display: none;
  }
  nav#mobileMenu li.has-submenu > a.active:after {
    content: "-";
  }
  nav#mobileMenu ul ul > li a {
    padding: 7px 20px;
    font-size: 16px;
    color: #fff;
  }
  nav#mobileMenu ul ul > li a,
  nav#mobileMenu ul ul > li p {
    padding: 10px 20px;
    line-height: normal;
    -webkit-text-fill-color: #fff;
    display: block;
  }
  .mainmenu ul li ul li {
    border: none;
  }

  nav#mobileMenu > ul > li > a,
  nav#mobileMenu > ul > li > p {
    padding: 10px;
    background: transparent;
    -webkit-text-fill-color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.13);
    text-transform: uppercase;
    font-weight: 500;
  }
  nav#mobileMenu p {
    margin: 0;
    line-height: normal;
  }
  .responsivemenu li p span {
    float: right;
  }
}

/* ===================== HEADER ======================*/
.gauto-header-top-area {
  background: #020202 none repeat scroll 0 0;
  color: #eee;
  margin-bottom: 10px;
}

.header-top-left {
  position: relative;
  z-index: 1;
  padding: 10px 0;
}

.header-top-left:before {
  position: absolute;
  background: #e79e3e none repeat scroll 0 0;
  content: "";
  top: 0;
  right: 37%;
  width: 2030px;
  height: 120%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.header-top-right {
  text-align: right;
  padding: 10px 0;
}

.header-top-left p {
  position: relative;
  top: 5px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.header-top-left p svg {
  width: 25px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  background: #fff none repeat scroll 0 0;
  color: #e79e3e;
  border-radius: 50%;
  margin: 0 2px;
  padding: 5px;
}

.header-top-right > .dropdown {
  display: inline-block;
}

.header-top-right > .dropdown button {
  background: transparent;
  color: #eee;
  border: medium none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

.header-top-right > .dropdown .dropdown-menu.show {
  border-radius: 0;
  border: medium none;
  background: #020202 none repeat scroll 0 0;
  color: #fff;
  margin: 0;
  left: auto !important;
  right: 0 !important;
  padding: 0;
  min-width: 130px;
  transition: all 0.4s ease 0s;
}

.header-top-right > .dropdown .dropdown-menu a {
  padding: 5px 10px;
  border-bottom: 1px solid #444;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
}

.header-top-right > .dropdown .dropdown-menu a:hover {
  background: #2e2e2e none repeat scroll 0 0;
}

.header-top-right > .dropdown .dropdown-menu li img {
  margin-right: 5px;
}

.header-top-right > a {
  color: #eee;
  text-transform: capitalize;
  margin-right: 13px;
}
.header-top-right > a svg {
  margin-right: 5px;
}
.gauto-main-header-area {
  padding: 15px 0;
}

.header-promo {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.single-header-promo {
  display: flex;
  align-items: center;
  flex: 1 1;
  justify-content: flex-end;
}

.header-promo-icon {
  font-size: 45px;
  margin-right: 10px;
}

.header-promo-info {
  color: #555;
}

.header-promo-info h3 {
  font-size: 20px;
  line-height: 30px;
  color: #001238;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.header-action {
  text-align: right;
}

.header-action a {
  display: inline-block;
  text-align: center;
  padding: 10px 25px 10px 0px;
  background: #e79e3e none repeat scroll 0 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

.header-action a:before {
  position: absolute;
  background: #e79e3e none repeat scroll 0 0;
  content: "";
  top: 0;
  left: -25px;
  width: 35%;
  height: 100%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.header-action a i {
  margin-right: 2px;
}

.gauto-mainmenu-area {
  background: #020202 none repeat scroll 0 0;
  border-bottom: 3px solid #363636;
}

.mainmenu ul {
  text-align: left;
}

.mainmenu ul li {
  display: inline-block;
  position: relative;
}

.mainmenu ul li a {
  color: #eee;
  display: block;
  padding: 20px 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.mainmenu ul li:hover > a,
.mainmenu ul li.active > a {
  color: #e79e3e;
}

.mainmenu ul li ul {
  position: absolute;
  top: 120%;
  left: 0;
  width: 180px;
  background: #fff none repeat scroll 0 0;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition: all 0.3s ease 0s;
}

.mainmenu ul li:hover ul {
  visibility: visible;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  top: 100%;
}

.mainmenu ul li ul li {
  display: block;
  border-bottom: 1px solid #eee;
}

.mainmenu ul li ul li:last-child {
  border-bottom: 0px solid #eee;
}

.mainmenu ul li ul li a {
  color: #001238;
  padding: 10px 15px;
  display: block;
  font-size: 14px;
}

.mainmenu ul li ul li a:hover {
  padding-left: 20px;
}

.main-search-right {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  position: relative;
}

.header-cart-box #dropdownMenu1 {
  border: medium none;
  background: url(/src/img/icon-cart.png) no-repeat scroll 0 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  top: 8px;
}

.header-cart-box #dropdownMenu1 > span {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #e79e3e none repeat scroll 0 0;
  font-size: 10px;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  right: -6px;
  top: -10px;
  text-align: center;
}

.cart-icon.dropdown-toggle:after {
  visibility: hidden !important;
}

.search-box form {
  position: relative;
  background: #1a1a1a none repeat scroll 0 0;
  height: 40px;
  width: 100%;
  border-radius: 20px;
}

.search-box form input {
  position: absolute;
  left: 0;
  width: 100%;
  border: medium none;
  background: transparent;
  padding: 5px 10px;
  height: 100%;
  color: #eee;
}

.search-box form button {
  position: absolute;
  right: 0;
  background: transparent;
  color: #eee;
  border: medium none;
  height: 100%;
  width: 22%;
  cursor: pointer;
}

.search-box {
  width: 100%;
  margin-left: 20px;
}

.dropdown-menu.cart-dropdown {
  left: auto;
  min-width: 320px;
  right: 0;
  padding: 20px;
  border-radius: 0 !important;
  top: 80px;
  margin-top: -1px;
}

.login .dropdown {
  height: 80px;
  padding: 30px 0;
}

.header-cart-box .dropdown.show .cart-dropdown {
  left: auto !important;
  right: 0px !important;
  border-radius: 0px;
  padding: 20px 20px;
  min-width: 300px;
  margin-top: 45px;
  z-index: 99;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border: 3px solid #ddd;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.product-remove {
  float: right;
  font-size: 16px !important;
  color: #e79e3e;
}

.cart-btn-pro-img {
  float: left;
  margin-bottom: 0;
  margin-right: 20px;
  width: 75px;
}

.cart-btn-pro-img > a {
  padding: 0 !important;
}

.cart-dropdown > ul.product_list > li {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.cart-btn-pro-cont h4,
.cart-btn-pro-cont h4 a {
  font-size: 17px;
  line-height: 30px;
  color: #001238;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.cart-btn-pro-cont p {
  color: #777;
  font-size: 14px;
}

.cart-btn-pro-cont span.price {
  color: #111;
  font-weight: 500;
}

.cart-subtotal p {
  color: #444;
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
}

.cart-subtotal p span {
  color: #111;
  font-weight: 500;
  font-size: 16px;
}

.cart-btn {
  text-align: center;
  background: #020202 none repeat scroll 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 15px auto 0;
  transition: all 0.4s ease 0s;
}

.cart-btn a {
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 15px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

.cart-btn:after {
  position: absolute;
  content: "";
  width: 66%;
  height: 100%;
  background: #e79e3e none repeat scroll 0 0;
  right: -36px;
  z-index: -1;
  -webkit-transform: skewX(40deg);
  transform: skewX(40deg);
  transition: all 0.4s ease 0s;
}

.cart-btn:hover {
  background: #e79e3e none repeat scroll 0 0;
}

.cart-btn:hover:after {
  background: #020202 none repeat scroll 0 0;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
.responsiveMenu {
  display: none;
}
@media (min-width: 992px) and (max-width: 1169px) {
  .header-top-left p {
    letter-spacing: 0;
  }
  .header-top-left p i {
    height: 20px;
    width: 20px;
    line-height: 20px;
  }
  .header-promo-info h3 {
    font-size: 17px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-top-left:before {
    right: 0;
  }
  .header-top-left:before {
    right: 0;
  }
  .gauto-responsive-menu {
    display: block;
    width: 50%;
    left: 0;
  }
  .header-action {
    display: none;
  }
  .mainmenu {
    display: none;
  }
  .search-box {
    display: none;
  }
  .main-search-right {
    justify-content: space-between;
    margin-top: 0;
  }
  .header-cart-box #dropdownMenu1 {
    position: relative;
    z-index: 999;
  }
  .gauto-about-area {
    padding-bottom: 20px;
  }
  .gauto-mainmenu-area {
    padding: 15px 0;
    min-height: 60px;
  }
  .header-cart-box {
    position: absolute;
    right: 20px;
    top: 0;
  }
  .responsiveMenu {
    display: block !important;
  }
  .site-logo {
    margin-top: 5px;
  }
}
@media (max-width: 767px) {
  .header-top-left:before {
    display: none;
  }
  .header-top-left {
    display: none;
  }
  .header-top-right {
    text-align: center;
  }
  .gauto-responsive-menu {
    display: block;
  }
  .site-logo {
    text-align: center;
    width: 55%;
    margin: 0 auto;
  }
  .site-logo img {
    width: 100%;
  }
  .gauto-about-area {
    padding-bottom: 50px;
  }
  .header-promo,
  .header-action {
    display: none;
  }
  .gauto-header-top-area {
    margin-bottom: 0;
  }
  .mainmenu {
    display: none;
  }
  .gauto-header-top-area {
    background: #fff none repeat scroll 0 0;
    border-bottom: 1px solid #eee;
  }
  .brochures a i {
    display: none;
  }
  .header-top-left p,
  .header-top-right > a,
  .header-top-right > .dropdown button {
    color: #001238 !important;
  }
  .header-top-left p i {
    background: #e79e3e none repeat scroll 0 0;
    color: #fff;
  }
  .search-box {
    display: none;
  }
  .header-cart-box #dropdownMenu1 {
    float: right;
  }
  .service-text h3 {
    font-size: 19px;
  }
  .header-cart-box {
    width: 95%;
  }
  .login.dropdown {
    position: relative;
    z-index: 999999;
    width: 100%;
  }
  .gauto-mainmenu-area {
    min-height: 50px;
  }
  .header-cart-box .dropdown.show .cart-dropdown {
    margin-top: 35px;
    min-width: 260px;
  }
  .cart-btn-pro-img {
    width: 60px;
    margin-right: 10px;
  }
  .cart-btn-pro-cont {
    overflow: hidden;
  }
  .header-cart-box #dropdownMenu1 {
    top: 0;
  }
  .main-search-right {
    margin-top: 0;
    padding: 7px 0;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-top-left:before {
    display: none;
  }
  .header-top-left {
    display: none;
  }
  .header-top-right {
    text-align: center;
  }
  .gauto-responsive-menu {
    display: block;
  }
  .site-logo {
    text-align: center;
    width: 45%;
    margin: 0 auto;
  }
  .gauto-about-area {
    padding-bottom: 50px;
  }
  .site-logo img {
    width: 100%;
  }
  .single-service {
    padding: 20px;
  }
  .service-text h3 {
    font-size: 18px;
    letter-spacing: 0;
  }
  .header-promo,
  .header-action {
    display: none;
  }
  .gauto-header-top-area {
    margin-bottom: 0;
  }
  .mainmenu {
    display: none;
  }
  .gauto-header-top-area {
    background: #fff none repeat scroll 0 0;
    border-bottom: 1px solid #eee;
  }
  .header-top-left p,
  .header-top-right > a,
  .header-top-right > .dropdown button {
    color: #001238;
  }
  .header-top-left p i {
    background: #e79e3e none repeat scroll 0 0;
    color: #fff;
  }
  .offer-action:after {
    width: 62%;
  }
  .service-details-image img {
    width: 100%;
  }
  .search-box {
    display: none;
  }
  .header-cart-box #dropdownMenu1 {
    float: right;
  }
  .header-cart-box {
    width: 95%;
  }
  .login.dropdown {
    position: relative;
    z-index: 999999;
    width: 100%;
  }
  .gauto-mainmenu-area {
    min-height: 50px;
  }
  .header-cart-box .dropdown.show .cart-dropdown {
    margin-top: 35px;
    min-width: 260px;
  }
  .cart-btn-pro-img {
    width: 60px;
    margin-right: 10px;
  }
  .cart-btn-pro-cont {
    overflow: hidden;
  }
  .site-logo {
    text-align: center;
    width: 35%;
    margin: 0 auto;
  }
  .gauto-about-area {
    padding-bottom: 50px;
  }
  .site-logo img {
    width: 100%;
  }
}

/* ===================== HERO ======================*/
.gauto-slider-area {
  position: relative;
}

.gauto-main-slide {
  background-color: #eee;
  background-position: center center;
  background-size: cover;
  height: 640px;
}

.slider-content-holder {
  width: 100%;
  text-align: center;
}

.slider-text {
  text-align: left;
}

.gauto-main-caption {
  display: table;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
}

.gauto-caption-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.slider-text h2 {
  color: #fff;
  font-size: 65px;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-transform: capitalize;
  letter-spacing: 3px;
  line-height: 1.2;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

.slider-text h2 span {
  color: #fff;
  font-style: italic;
  font-weight: 600;
}

.slider-text p {
  color: #fff;
  font-size: 26px;
  letter-spacing: 2px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-text h2 {
    font-size: 50px;
  }
  .gauto-main-slide {
    height: 460px;
  }
}
@media (max-width: 767px) {
  .slider-text {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  .slider-text p {
    font-size: 20px;
  }
  .slider-text h2 {
    font-size: 35px;
  }
  .gauto-main-slide {
    height: 380px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .slider-text {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  .slider-text p {
    font-size: 20px;
  }
  .slider-text h2 {
    font-size: 35px;
  }
  .gauto-main-slide {
    height: 380px;
  }
}

/* ===================== FIND CAR ======================*/


.gauto-find-area {
  margin-top: -75px;
}

.find-box {
  background: #fff url(/static/media/find-box-bg.5264afa6.png) no-repeat scroll 0 0;
  padding: 30px;
  position: relative;
  z-index: 999;
  box-shadow: 3px 0 29px rgba(0, 0, 0, 0.26);
}

.find-text h3 {
  color: #fff;
  font-size: 33px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  line-height: 40px;
}

.find-form p input,
.find-form p select,
.e-control-wrapper {
  width: 100% !important;
  border: 2px solid #f0f0ff !important;
  padding: 5px 10px;
  height: 45px !important;
  color: #111 !important;
  transition: all 0.4s ease 0s;
}
input#datepicker,
input#timepicker {
  height: auto !important;
  border: none !important;
  font-size: 14px;
  font-weight: 500;
}
.find-form p input:focus,
.find-form p select:focus {
  border: 2px solid #e79e3e !important;
}

.find-form form p {
  margin: 15px 0;
  position: relative;
}

.datepicker {
  z-index: 9999;
}

.datepicker {
  border: 2px solid #f0f0ff;
  margin-top: -5px;
}

.datepicker .cell.day.selected,
.datepicker .cell.month.selected,
.datepicker .cell.year.selected,
.datepicker .cell.decade.selected {
  background: #ca3d26 none repeat scroll 0 0;
  color: #fff;
}

.datepicker .cell.day:hover,
.datepicker .cell.month:hover,
.datepicker .cell.year:hover,
.datepicker .cell.decade:hover {
  background: #ca3d26 none repeat scroll 0 0;
  color: #fff;
}

.popover.clockpicker-popover.bottom.clockpicker-align-left {
  border: 2px solid #f0f0ff;
}

.text-primary,
.clockpicker-popover .popover-title {
  color: #ef4836;
}

.datepicker .row.header a {
  margin: 0 3px;
  cursor: pointer;
  display: block;
  text-align: center;
  width: 100%;
}

.datepicker .day {
  font-size: 14px;
  text-align: center;
}

.popover-title span {
  color: #ef4836;
}

.popover-title .text-primary {
  color: #111 !important;
}

.clockpicker input {
  font-size: 14px;
  color: #7c8a97;
}

.clockpicker input:focus {
  box-shadow: 0 0 0 rgba(0, 123, 255, 0.25);
  border: 2px solid #f0f0ff;
  border-radius: 0;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .find-box {
    background: #fff url(/static/media/find-box-bg.5264afa6.png) no-repeat scroll -45px 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .find-box {
    background: #fff url(/static/media/find-box-bg.5264afa6.png) no-repeat scroll -120px 0;
  }
  .find-text {
    margin-top: 25px;
  }
  .find-text h3 {
    font-size: 25px;
  }
  .find-form form p {
    margin: 10px 0;
  }
}
@media (max-width: 767px) {
  .find-box {
    background: #fff none repeat scroll 0 0;
  }
  .find-text {
    margin-top: 0;
  }
  .find-text h3 {
    color: #001238;
    text-align: center;
    font-size: 25px;
  }
  .find-form {
    margin-top: 5px;
  }
  .find-form form p {
    margin-top: 15px;
    margin-bottom: 0;
  }
  .gauto-find-area {
    margin-top: -55px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .find-box {
    background: #fff none repeat scroll 0 0;
  }
  .find-text {
    margin-top: 0;
  }
  .find-text h3 {
    color: #001238;
    text-align: center;
    font-size: 25px;
  }
}

/* ===================== ABOUT ======================*/
.about-left h4 {
  font-size: 20px;
  color: #e79e3e;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Rubik", sans-serif;
}

.about-left h2 {
  font-size: 40px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.about-list ul {
  -webkit-columns: 2;
  columns: 2;
  margin-top: 10px;
}

.about-list ul li {
  margin: 5px 0;
}
.about-list ul li span {
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  background: #e79e3e none repeat scroll 0 0;
  border-radius: 2px;
  line-height: 20px;
}
.about-list ul li span svg {
  fill: #ffffff;
  height: 12px;
}
.about-signature {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 20px;
}

.signature-left {
  width: 190px;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 3px solid #f0f0ff;
}

.signature-right h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #001238;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.about-right {
  margin-top: 30px;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .about-left h2 {
    font-size: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-left h2 {
    font-size: 35px;
  }
  .gauto-about-area {
    padding-bottom: 20px !important;
  }
}
@media (max-width: 767px) {
  .about-left h2 {
    font-size: 30px;
  }
  .about-list ul {
    -webkit-columns: 1;
    columns: 1;
  }
  .signature-left {
    width: 130px;
  }
  .gauto-about-area {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .about-left h2 {
    font-size: 30px;
  }
  .about-list ul {
    -webkit-columns: 1;
    columns: 1;
  }
  .signature-left {
    width: 130px;
  }
  .gauto-about-area {
    padding-bottom: 50px !important;
  }
}

/* ===================== SERVICE ======================*/
.gauto-service-area {
  background: #fbfbfd none repeat scroll 0 0;
  padding-bottom: 105px;
}

.service-page-area {
  padding-bottom: 70px;
}

.single-service {
  padding: 50px 30px;
  background: #fff none repeat scroll 0 0;
  border: 2px solid #fff;
  transition: all 0.4s ease 0s;
  position: relative;
  box-shadow: 0px 3px 14px 0px rgba(0, 11, 40, 0.06);
  margin: 30px 5px 5px 5px;
}
.service-page-area .single-service {
  margin: 30px 0 0 0;
}

.service-number {
  font-size: 55px;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 60px;
  height: 55px;
  font-weight: 500;
  line-height: 55px;
  color: rgba(35, 61, 92, 0.07);
  border-radius: 0 0 0 10px;
  z-index: 1;
  transition: all 0.3s;
}

.service-icon {
  width: 60px;
}

.service-text {
  margin-top: 25px;
}

.service-text h3 {
  font-size: 21px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.service-text a {
  color: #001238;
}

.service-text a:hover,
.service-text h3:hover {
  color: #e79e3e;
}
.service-slider .slide {
  padding: 0 15px;
}
.service-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}
.service-slider .slick-dots {
  margin-top: 30px;
  position: inherit;
  bottom: 0;
}
.service-slider .slick-dots li button:before {
  display: none;
}
.service-slider .slick-dots li button {
  font-size: 0px;
  line-height: 0;
  display: block;
  width: 6px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #020202;
  margin: auto;
}
.service-slider .slick-dots li.slick-active button {
  background: #e79e3e;
}
.service-slider .slick-dots li {
  width: auto;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .single-service {
    padding: 25px;
  }
  .service-text h3 {
    font-size: 19px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-service {
    margin: 30px 0 0 0;
    border: 0px solid #fff;
    padding: 30px 15px;
  }
  .service-text h3 {
    font-size: 17px;
    letter-spacing: 0;
  }
  .service-icon {
    width: 50px;
  }
  .service-menu {
    padding: 20px;
  }
}
@media (max-width: 767px) {
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-service {
    padding: 20px;
  }
  .service-text h3 {
    font-size: 18px;
    letter-spacing: 0;
  }
}

/* ===================== PROMO ======================*/
.gauto-promo-area {
  position: relative;
  background: url(/static/media/promo_bg.e7caf0de.jpg) no-repeat fixed 0 0 / cover;
  z-index: 1;
}

.gauto-promo-area:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
  z-index: -1;
}

.promo-box-left {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  padding-bottom: 50px;
}

.promo-box-left:after {
  position: absolute;
  background: #e79e3e none repeat scroll 0 0;
  content: "";
  top: 0;
  right: 55%;
  width: 2030px;
  height: 100%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.promo-box-left:before {
  position: absolute;
  background: #fff none repeat scroll 0 0;
  content: "";
  top: 0;
  right: 43%;
  width: 70px;
  height: 100%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.promo-box-right h3 {
  font-size: 34px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 50px;
}
@media (min-width: 992px) and (max-width: 1169px) {
  .promo-box-right h3 {
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .promo-box-right {
    padding: 50px 0;
  }
  .promo-box-right h3 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media (max-width: 767px) {
  .promo-box-left {
    display: none;
  }
  .promo-box-right {
    text-align: center;
  }
  .promo-box-right h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .promo-box-right a.gauto-btn {
    margin-bottom: 0;
  }
  .gauto-promo-area {
    padding: 70px 0;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .promo-box-left {
    display: none;
  }
  .promo-box-right {
    text-align: center;
  }
  .promo-box-right h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .promo-box-right a.gauto-btn {
    margin-bottom: 0;
  }
}

/* ===================== HOT OFFERS ======================*/
#offerTab {
  text-align: center;
  width: 100%;
  display: block;
  border: medium none;
  margin: 30px 0;
}

#offerTab li {
  display: inline-block;
  margin: 0 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: medium none;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: medium none;
}

.nav-tabs .nav-link {
  border: medium none;
}

#offerTab li.nav-item .nav-link,
#offerTab li.nav-item .nav-link.active {
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 25px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 1;
}

#offerTab li.nav-item .nav-link:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: #020202 none repeat scroll 0 0;
  z-index: -1;
  -webkit-transform: skew(40deg);
  transform: skew(40deg);
  transition: all 0.3s ease 0s;
}

#offerTab li.nav-item .nav-link:hover:after {
  background: #e79e3e none repeat scroll 0 0;
  -webkit-transform: skew(-40deg);
  transform: skew(-40deg);
}
#offerTab .nav-tabs {
  width: 100%;
  display: block;
  border: medium none;
  margin: 30px 0;
  justify-content: center;
}
#offerTab li.nav-item .nav-link.active:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
  background: #e79e3e none repeat scroll 0 0;
  -webkit-transform: skew(-40deg);
  transform: skew(-40deg);
}

.single-offers {
  text-align: center;
  padding: 20px;
  border: 3px solid #f0f0ff;
  margin-top: 30px;
}

.offer-image {
  width: 235px;
  margin: 0 auto;
  display: block;
}

.offer-image img {
  width: 100%;
}

.offer-text {
  margin-top: 20px;
}

.offer-text h3 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.offer-text h4 {
  color: #020202;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 8px;
}

.offer-text h4 span {
  text-transform: capitalize;
  color: #e79e3e;
  font-size: 16px;
}

.offer-action {
  text-align: center;
  background: #020202 none repeat scroll 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 25px auto 0;
  transition: all 0.4s ease 0s;
}

.offer-action a {
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 15px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  width: 47%;
}

.offer-action:after {
  position: absolute;
  content: "";
  width: 66%;
  height: 100%;
  background: #e79e3e none repeat scroll 0 0;
  right: -36px;
  z-index: -1;
  -webkit-transform: skewX(40deg);
  transform: skewX(40deg);
  transition: all 0.4s ease 0s;
}

.offer-action:hover {
  background: #e79e3e none repeat scroll 0 0;
}

.offer-action:hover:after {
  background: #020202 none repeat scroll 0 0;
}

.offer-text ul {
  text-align: center;
  margin-top: 10px;
}

.offer-text ul li {
  display: inline-block;
  font-size: 14px;
  margin: 0 3px;
}

.offer-text ul li svg {
  margin-right: 5px;
  fill: #e79e3e;
}

@media (min-width: 768px) and (max-width: 991px) {
  .offer-tabs .row .col-lg-4:last-child {
    display: none;
  }
  .offer-tabs .row .col-lg-4,
  .gauto-blog-area .row .col-lg-4 {
    width: 50%;
  }
  .offer-action:after {
    width: 62%;
  }
}
@media (max-width: 767px) {
  #offerTab li {
    display: block;
    margin-bottom: 5px;
  }
  #offerTab li a {
    display: block !important;
  }
  #offerTab li.nav-item .nav-link.active:after,
  #offerTab li.nav-item .nav-link:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
  #offerTab li.nav-item .nav-link:hover:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
  .single-offers {
    padding: 10px;
  }
  .offer-text ul li {
    margin: 0 1px !important;
    display: inline-block !important;
  }
  .offer-text ul li i {
    margin-right: 1px;
  }
  #offerTab li.nav-item .nav-link {
    display: block;
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .offer-action:after {
    width: 62%;
  }
  .single-offers {
    padding: 15px;
  }
  .offer-text ul li {
    margin: 0 3px;
  }
  .offer-text ul li i {
    margin-right: 3px;
  }
  #offerTab li {
    display: block;
    margin-bottom: 5px;
  }
  #offerTab li a {
    display: block !important;
  }
  #offerTab li.nav-item .nav-link.active:after,
  #offerTab li.nav-item .nav-link:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
  #offerTab li.nav-item .nav-link:hover:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}

/* ===================== TESTIMONIAL ======================*/
.gauto-testimonial-area {
  position: relative;
  background: url(/static/media/promo_bg.e7caf0de.jpg) no-repeat fixed 0 0 / cover;
  z-index: 1;
  padding-bottom: 100px;
}

.gauto-testimonial-area:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
  z-index: -1;
}

.gauto-testimonial-area .site-heading h2 {
  color: #fff;
}

.single-testimonial {
  background: #fff none repeat scroll 0 0;
  padding: 30px;
  margin-top: 30px;
}

.testimonial-text {
  position: relative;
}

.testimonial-text svg {
  position: absolute;
  color: #001238;
  font-size: 80px;
  font-family: Fontawesome;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.testimonial-meta {
  margin-top: 30px;
}

.client-image {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  float: left;
}

.client-image img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}

.client-info h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #001238;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.client-info {
  overflow: hidden;
}

.client-info p {
  color: #e79e3e;
}
.testimonial-slider .slide {
  padding: 0 15px;
}
.testimonial-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}
.testimonial-slider .slick-dots {
  margin-top: 30px;
  position: inherit;
  bottom: 0;
}
.testimonial-slider .slick-dots li button:before {
  display: none;
}
.testimonial-slider .slick-dots li button {
  font-size: 0px;
  line-height: 0;
  display: block;
  width: 6px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #ffffff;
  margin: auto;
}
.testimonial-slider .slick-dots li.slick-active button {
  background: #e79e3e;
}
.testimonial-slider .slick-dots li {
  width: auto;
}

/* ===================== TEAM ======================*/
.gauto-driver-area {
  padding-bottom: 70px;
}

.single-driver {
  margin-top: 30px;
}

.driver-img-details img {
  width: 100%;
}

.driver-image {
  position: relative;
  overflow: hidden;
}

.driver-image img {
  width: 100%;
}

.driver-image .hover {
  position: absolute;
  right: 0px;
  top: 20px;
  z-index: 111;
}

.driver-image .hover .social-icon li + li {
  margin-top: 10px;
  display: block;
}

.driver-image .hover .social-icon li a {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #e79e3e;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease-in;
}

.driver-image .hover .social-icon li a:hover {
  background-color: #001238;
}

.driver-image .hover .social-icon li:nth-child(1) {
  position: absolute;
  top: 20px;
  -webkit-transform: translateX(80px);
  transform: translateX(80px);
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  transition-delay: 100ms;
}

.driver-image .hover .social-icon li:nth-child(2) {
  position: absolute;
  top: 60px;
  -webkit-transform: translateX(80px);
  transform: translateX(80px);
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  transition-delay: 200ms;
}

.driver-image .hover .social-icon li:nth-child(3) {
  position: absolute;
  top: 110px;
  -webkit-transform: translateX(80px);
  transform: translateX(80px);
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  transition-delay: 300ms;
}

.driver-image .hover .social-icon li:nth-child(4) {
  position: absolute;
  top: 160px;
  -webkit-transform: translateX(80px);
  transform: translateX(80px);
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  transition-delay: 400ms;
}

.single-driver:hover .driver-image .hover .social-icon li:nth-child(1) {
  -webkit-transform: translateX(-60px);
  transform: translateX(-60px);
}

.single-driver:hover .driver-image .hover .social-icon li:nth-child(2) {
  -webkit-transform: translateX(-60px);
  transform: translateX(-60px);
}

.single-driver:hover .driver-image .hover .social-icon li:nth-child(3) {
  -webkit-transform: translateX(-60px);
  transform: translateX(-60px);
}

.single-driver:hover .driver-image .hover .social-icon li:nth-child(4) {
  -webkit-transform: translateX(-60px);
  transform: translateX(-60px);
}

.driver-image {
  position: relative;
}

.driver-image:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000 none repeat scroll 0 0;
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.driver-text {
  text-align: center;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.11);
  padding: 30px 15px;
}

.driver-text h3 {
  font-size: 22px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 5px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.driver-text a {
  color: #001238;
}

.driver-text a h3:hover {
  color: #e79e3e;
}

.driver_page .pagination-box-row {
  text-align: center;
}

.load-more {
  text-align: center;
  margin-top: 20px;
}

.load-more a.gauto-btn {
  color: #e79e3e;
  margin-bottom: 0;
}

.load-more a.gauto-btn:hover {
  color: #fff;
}

/* ===================== HELP ======================*/

.call-box {
  background: url(/static/media/footer-bg.7e04edb4.jpg) no-repeat scroll 0 0 / cover;
  text-align: center;
  border-radius: 5px;
  position: relative;
  padding: 50px 0;
  z-index: 1;
}

.call-box-inner {
  width: 70%;
  margin: 0 auto;
}

.call-box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
  z-index: -1;
  border-radius: 5px;
}

.call-box h2 {
  font-size: 40px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 50px;
}

.call-box h2 span {
  color: #e79e3e;
}

.call-box-inner p {
  color: #b6b6b6;
}

.call-number {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  align-content: center;
  align-items: center;
}

.call-icon {
  font-size: 50px;
  margin-right: 30px;
  color: #fff;
}

.call-text {
  text-align: left;
}

.call-text p {
  text-transform: capitalize;
  color: #e04639;
  font-size: 17px;
  margin-bottom: 5px;
}

.call-text h4 {
  color: #eee;
  font-size: 20px;
  letter-spacing: 2px;
}

.call-text h4 a {
  color: #eee;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .call-box-inner {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .call-box-inner {
    width: 100%;
    padding: 0 15px;
  }
  .call-box h2 {
    font-size: 28px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .call-box-inner {
    width: 100%;
    padding: 0 15px;
  }
  .call-box h2 {
    font-size: 28px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .call-box h2 {
    font-size: 28px;
    line-height: 40px;
  }
  .call-box-inner {
    width: 100%;
    padding: 0 15px;
  }
}

/* ===================== BLOG ======================*/
.single-blog {
  border: 3px solid #f0f0ff;
  margin-top: 30px;
}

.blog-text {
  padding: 15px 20px;
}

.blog-text h3 {
  font-size: 18px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.blog-text h3 a {
  color: #001238;
}

.blog-text h3 a:hover {
  color: #e79e3e;
}

.blog-meta-home {
  padding-top: 15px;
  border-top: 3px solid #f0f0ff;
}

.blog-image img {
  width: 100%;
}

.blog-meta-home {
  padding-top: 15px;
  border-top: 3px solid #f0f0ff;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.blog-meta-left {
  display: flex;
  flex: 1 1;
}

.blog-meta-right {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

.blog-meta-right p {
  margin-left: 15px;
}

.blog-meta-right p svg {
  margin-right: 5px;
  fill: #e79e3e;
}

.gauto-blog-page-area {
  padding-top: 40px;
}

.blog-page-left .blog-text h3 {
  font-size: 24px;
}

.blog-text ul {
  margin-bottom: 20px;
}

.blog-text ul li {
  display: inline-block;
  margin-right: 10px;
}

.blog-text ul li i {
  margin-right: 5px;
  color: #e79e3e;
}

.blog-text ul li a {
  color: #111;
}

.blog-text a.gauto-btn {
  color: #e79e3e;
}

.blog-text a.gauto-btn:hover {
  color: #fff;
}

.blog-text a.gauto-btn {
  color: #e79e3e;
  margin: 20px 0 0 0;
}

.blog-page-right .sidebar-widget {
  margin-top: 30px;
  margin-bottom: 0;
}

.blog-page-right .recent-text h4 {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 1px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .gauto-blog-area .row .col-lg-4:last-child {
    display: none;
  }
  .offer-tabs .row .col-lg-4,
  .gauto-blog-area .row .col-lg-4 {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .blog-text {
    padding: 15px 10px;
  }
  .blog-text ul li {
    margin-right: 5px;
  }
  .blog-page-left .blog-text h3 {
    font-size: 22px;
    line-height: 36px;
  }
  .blog-page-right {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .blog-text {
    padding: 15px 10px;
  }
  .blog-text ul li {
    margin-right: 5px;
  }
  .blog-page-left .blog-text h3 {
    font-size: 22px;
    line-height: 36px;
  }
  .blog-page-right {
    margin-top: 30px;
  }
}

/* ===================== FOOTER ======================*/
.footer-top-area {
  padding: 50px 0;
  background: url(/static/media/footer-bg.7e04edb4.jpg) no-repeat scroll 0 0 / cover;
  position: relative;
  z-index: 1;
}

.footer-top-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1;
}

.single-footer {
  clear: both;
  color: #aaaeb1;
}

.quick-links {
  float: left;
  width: 45%;
}

.footer-logo {
  margin-bottom: 15px;
}

.single-footer h3,
.footer-address h3 {
  font-size: 17px;
  color: #eee;
  letter-spacing: 1px;
  margin-bottom: 30px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  position: relative;
}

.single-footer > h3:after,
.footer-address > h3:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 18%;
  height: 2px;
  background: #e79e3e;
  left: 0;
}

.quick_links {
  overflow: hidden;
}

.quick-links li a {
  color: #aaaeb1;
}

.quick-links li a:hover {
  color: #fff;
}

.footer-address {
  margin-top: 30px;
}

.newsletter_box {
  margin-top: 30px;
}

.newsletter_box form {
  width: 230px;
  height: 40px;
  background: #222;
  position: relative;
  border-radius: 5px;
}

.newsletter_box form input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #272727;
  height: 100%;
  border: medium none;
  padding: 5px 10px;
  color: #eee;
  border-radius: 5px;
}

.newsletter_box form button {
  position: absolute;
  border: medium none;
  background: transparent;
  right: 10px;
  height: 100%;
  color: #e79e3e;
  cursor: pointer;
}

.footer-post-image {
  width: 70px;
  float: left;
  margin-right: 10px;
}

.footer-post-image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.footer-post-text {
  overflow: hidden;
}

.single-footer-post {
  clear: both;
  margin-bottom: 15px;
  overflow: hidden;
}

.footer-post-text h3 {
  color: #eee;
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 23px;
  font-weight: normal;
}

.footer-post-text h3 a {
  color: #aaaeb1;
}

.footer-post-text h3 a:hover {
  color: #eee;
}

.footer-post-text p {
  color: #e97369;
  font-style: italic;
  font-size: 13px;
}

.footer-bottom-area {
  padding: 20px 0;
  background: #020202;
  border-top: 1px solid #333;
}

.copyright p {
  color: #aaa;
  font-size: 14px;
  text-transform: capitalize;
}

.copyright p svg {
  fill: #e79e3e;
  margin: 0 2px;
}

.copyright p a {
  color: #aaa;
}

.footer-social ul {
  text-align: right;
}

.footer-social ul li {
  display: inline-block;
  margin-left: 10px;
}

.footer-social ul li a {
  display: block;
  color: #eee;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .single-footer {
    margin: 20px auto 20px;
  }
  .newsletter_box form {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .single-footer {
    margin: 20px 0;
  }
  .copyright {
    text-align: center;
    margin-bottom: 10px;
  }
  .footer-social ul {
    text-align: center;
  }
  .newsletter_box form {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-footer {
    margin: 20px 0;
  }
  .copyright {
    text-align: center;
    margin-bottom: 10px;
  }
  .footer-social ul {
    text-align: center;
  }
  .newsletter_box form {
    width: 100%;
  }
}

/* ===================== PAGE TITLE ======================*/
.gauto-breadcromb-area {
  background: url(/static/media/breadcromb.7705b9f0.jpg) no-repeat fixed 0 0 / cover;
  position: relative;
  z-index: 1;
}

.gauto-breadcromb-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.72) none repeat scroll 0 0;
  z-index: -1;
}

.breadcromb-box {
  text-align: center;
}

.breadcromb-box h3 {
  font-size: 40px;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.breadcromb-box li {
  display: inline-block;
  margin: 0 2px;
  color: #fff !important;
  font-size: 16px;
  text-transform: capitalize;
}

.breadcromb-box li a {
  color: #fff;
}

.breadcromb-box li svg {
  fill: #e79e3e;
  margin-right: 2px;
}
@media (max-width: 767px) {
  .breadcromb-box h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .breadcromb-box h3 {
    font-size: 30px;
  }
}

/* ===================== ABOUT PAGE ======================*/
.about-page-left h4 {
  font-size: 20px;
  color: #e79e3e;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Rubik", sans-serif;
}

.about-page-left h3 {
  font-size: 36px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.about-page-left p span {
  color: #111;
  text-transform: capitalize;
}

.about-page-left p {
  margin: 0 0 10px;
}

.about-page-call {
  display: flex;
  margin-top: 30px;
  align-content: center;
  align-items: center;
}

.page-call-icon {
  font-size: 50px;
  margin-right: 30px;
  color: #e79e3e;
}

.call-info p {
  text-transform: capitalize;
  color: #e79e3e;
  font-size: 17px;
  margin-bottom: 5px;
}

.call-info h4 {
  color: #001238;
  font-size: 20px;
  letter-spacing: 2px;
}

.call-info h4 a {
  color: #001238;
}

.about-promo-text {
  text-align: center;
  width: 55%;
  margin: 0 auto;
}

.gauto-about-promo {
  padding-bottom: 50px;
  background: #fbfbfd none repeat scroll 0 0;
}

.about-promo-text h3 {
  font-size: 36px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.about-promo-text h3 span {
  color: #e79e3e;
}

.about-promo-image {
  margin-top: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .about-page-left h3,
  .about-promo-text h3 {
    font-size: 30px;
  }
  .about-page-right {
    margin-top: 30px;
  }
  .about-page-right img {
    width: 100%;
  }
  .about-promo-text {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .about-page-left h3,
  .about-promo-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .about-page-right {
    margin-top: 30px;
  }
  .about-promo-text {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .about-page-left h3,
  .about-promo-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .about-page-right {
    margin-top: 30px;
  }
  .about-promo-text {
    width: 100%;
  }
}

/* ===================== SERVICE ======================*/
.gauto-service-area {
  background: #fbfbfd none repeat scroll 0 0;
  padding-bottom: 105px;
}

.service-page-area {
  padding-bottom: 70px;
}

.single-service {
  padding: 50px 30px;
  background: #fff none repeat scroll 0 0;
  border: 2px solid #fff;
  transition: all 0.4s ease 0s;
  position: relative;
  box-shadow: 0px 3px 14px 0px rgba(0, 11, 40, 0.06);
  margin: 30px 5px 5px 5px;
}
.service-page-area .single-service {
  margin: 30px 0 0 0;
}

.service-number {
  font-size: 55px;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 60px;
  height: 55px;
  font-weight: 500;
  line-height: 55px;
  color: rgba(35, 61, 92, 0.07);
  border-radius: 0 0 0 10px;
  z-index: 1;
  transition: all 0.3s;
}

.service-icon {
  width: 60px;
}

.service-text {
  margin-top: 25px;
}

.service-text h3 {
  font-size: 21px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.service-text a {
  color: #001238;
}

.service-text a:hover,
.service-text h3:hover {
  color: #e79e3e;
}
.service-slider .slide {
  padding: 0 15px;
}
.service-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}
.service-slider .slick-dots {
  margin-top: 30px;
  position: inherit;
  bottom: 0;
}
.service-slider .slick-dots li button:before {
  display: none;
}
.service-slider .slick-dots li button {
  font-size: 0px;
  line-height: 0;
  display: block;
  width: 6px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #020202;
  margin: auto;
}
.service-slider .slick-dots li.slick-active button {
  background: #e79e3e;
}
.service-slider .slick-dots li {
  width: auto;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .single-service {
    padding: 25px;
  }
  .service-text h3 {
    font-size: 19px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-service {
    margin: 30px 0 0 0;
    border: 0px solid #fff;
    padding: 30px 15px;
  }
  .service-text h3 {
    font-size: 17px;
    letter-spacing: 0;
  }
  .service-icon {
    width: 50px;
  }
  .service-menu {
    padding: 20px;
  }
}
@media (max-width: 767px) {
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-service {
    padding: 20px;
  }
  .service-text h3 {
    font-size: 18px;
    letter-spacing: 0;
  }
}

/* ===================== SERVICE DETAILS ======================*/
.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar-widget:last-child {
  margin-bottom: 0;
}

.service-menu {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li {
  margin-bottom: 5px;
}

.service-menu li:last-child {
  margin-bottom: 0;
}

.service-menu li a {
  display: block;
  padding: 10px 20px;
  background: #fff none repeat scroll 0 0;
  color: #001238;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.service-menu li a span {
  margin-left: 5px;
}

.service-menu li.active a,
.service-menu li a:hover {
  color: #e79e3e;
  padding-left: 25px;
}

.brochures {
  text-align: center;
  background: #020202 none repeat scroll 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 25px auto 0;
  transition: all 0.4s ease 0s;
}

.brochures:after {
  position: absolute;
  content: "";
  width: 62%;
  height: 100%;
  background: #e79e3e none repeat scroll 0 0;
  right: -36px;
  z-index: -1;
  -webkit-transform: skewX(40deg);
  transform: skewX(40deg);
  transition: all 0.4s ease 0s;
}

.brochures a {
  display: inline-block;
  text-transform: uppercase;
  padding: 15px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  width: 47%;
}

.brochures a i {
  margin-right: 5px;
}

.brochures:hover {
  background: #e79e3e none repeat scroll 0 0;
}

.brochures:hover:after {
  background: #020202 none repeat scroll 0 0;
}

.service-page-banner {
  background: url(/static/media/find-box-bg.5264afa6.png) no-repeat scroll 0 0 / cover;
  padding: 30px;
  text-align: center;
  color: #fff;
}

.service-page-banner > h3 {
  font-size: 28px !important;
  color: #fff !important;
  letter-spacing: 1px !important;
  margin-bottom: 15px !important;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  line-height: 40px !important;
}

.service-page-banner a {
  margin-bottom: 0;
  border-color: #fff;
  margin-top: 20px;
}

.service-page-banner a:before,
.service-page-banner a:after {
  content: "";
  display: block;
  position: absolute;
  border-color: #fff;
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  transition: all 0.3s ease-in-out;
}

.service-page-banner a:hover {
  color: #e79e3e;
  background-color: #fff;
  border-color: #fff;
}

.service-page-banner a:hover:before,
.service-page-banner a:hover:after {
  border-color: #fff;
}

.service-details-right h3,
.sidebar-widget h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.service-details-right p {
  margin: 0 0 10px;
}

.pad-right-sm {
  padding-right: 7px;
}

.pad-left-sm {
  padding-left: 7px;
}

.service-details-image {
  margin-top: 10px;
}

.service-details-list ul {
  width: 50%;
  float: left;
  margin-top: 20px;
}

.service-details-list li {
  margin: 5px 0;
}

.service-details-list li svg {
  fill: #e79e3e;
  margin-right: 5px;
}

.service-accordion {
  margin-top: 20px;
}

.service-accordion h3,
.sidebar-widget h3 {
  font-size: 20px;
}

.Tags-catagory {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.Tags-catagory li {
  display: inline-block;
  border: medium none !important;
  padding: 0 !important;
  margin: 2px 0;
}

.Tags-catagory a {
  color: #001238;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  padding: 5px 15px;
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
}

.Tags-catagory a:hover {
  color: #e79e3e;
}

.single_faq_accordian {
  margin-top: 10px;
}

.faq_accordian_header > button {
  display: block;
  text-align: left;
  padding: 20px 20px 20px 85px;
  background: #020202 none repeat scroll 0 0;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  border: 0px solid rgba(0, 0, 0, 0.08);
  border-radius: 0;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.faq_accordian_header > button:hover,
.faq_accordian_header > button:focus {
  background: #020202 none repeat scroll 0 0;
  color: #fff;
  outline: medium none;
}

.faq_accordian_header > button:after {
  position: absolute;
  left: 20px;
  color: #fff;
  font-size: 18px;
  transition: all 0.4s ease 0s;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.faq_accordian_header > button:before {
  position: absolute;
  content: "";
  top: 0;
  width: 12%;
  height: 100%;
  left: -26px;
  background: #e79e3e;
  -webkit-transform: skew(-30deg);
  transform: skew(-30deg);
  transition: all 0.4s ease 0s;
}

.faq_accordian_header > button.collapsed:before {
  position: absolute;
  content: "";
  top: 0;
  width: 12%;
  height: 100%;
  left: -26px;
  background: #e79e3e;
  -webkit-transform: skew(30deg);
  transform: skew(30deg);
}

.faq_accordian_body {
  padding: 10px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #020202;
  box-shadow: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .service-details-right {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .service-details-right h3,
  .sidebar-widget h3 {
    font-size: 22px !important;
    line-height: 40px !important;
  }
  .pad-right-sm {
    padding-right: 15px;
  }
  .pad-left-sm {
    padding-left: 15px;
  }
  .service-details-list ul {
    width: 100% !important;
    float: none !important;
    margin: 0 !important;
  }
  .service-details-list.clearfix {
    margin-top: 30px;
  }
  .faq_accordian_header > button.collapsed:before {
    width: 30%;
  }
  .faq_accordian_header > button:before {
    width: 30%;
  }
  .service-details-right {
    margin-top: 30px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .service-details-right {
    margin-top: 30px !important;
  }
  .service-details-right h3,
  .sidebar-widget h3 {
    font-size: 22px;
    line-height: 40px;
  }
  .pad-right-sm {
    padding-right: 15px;
  }
  .pad-left-sm {
    padding-left: 15px;
  }
  .service-details-list ul {
    width: 100%;
    float: none;
    margin: 0;
  }
  .service-details-list.clearfix {
    margin-top: 30px;
  }
  .faq_accordian_header > button.collapsed:before {
    width: 20%;
  }
  .faq_accordian_header > button:before {
    width: 20%;
  }
}

/* ===================== CAR LIST ======================*/

.sidebar-widget form p input,
.sidebar-widget form p select,
.e-control-wrapper {
  width: 100% !important;
  border: 2px solid #f0f0ff !important;
  padding: 5px 10px;
  height: 45px !important;
  color: #111 !important;
  transition: all 0.4s ease 0s;
  background: #ffffff !important;
}
input#datepicker,
input#timepicker {
  height: auto !important;
  border: none !important;
  font-size: 14px;
  font-weight: 500;
}
.sidebar-widget form p input:focus,
.sidebar-widget form p select:focus {
  border: 2px solid #e79e3e;
}

.sidebar-widget form {
  background: #f4f4f5 none repeat scroll 0 0;
  padding: 15px;
}

.sidebar-widget form p {
  margin-bottom: 10px;
}

.sidebar-widget form p:last-child {
  margin-bottom: 0;
}

.sidebar-widget form p .nice-select.open .list {
  width: 100%;
}

.sidebar-widget form p button {
  margin-top: 20px;
  background: #fff none repeat scroll 0 0;
}

.property-page-heading {
  border: 0px solid #f0f0ff;
  padding: 7px 15px;
  background: #f4f4f5 none repeat scroll 0 0;
  display: flex;
  align-content: center;
  align-items: center;
}

.propertu-page-shortby {
  display: flex;
}

.propertu-page-shortby .nice-select {
  background-color: #f4f4f5;
  border-radius: 0;
  border: solid 0px #e8e8e8;
  width: 200px;
}

.propertu-page-head {
  flex: 1 1;
}

.propertu-page-head ul li {
  display: inline-block;
  margin-right: 10px;
}

.propertu-page-head ul li.active a {
  color: #111;
}

.paging_status {
  display: flex;
  flex: 1 1;
}

.propertu-page-head ul li a:hover,
.paging_status p {
  color: #111;
}

.propertu-page-shortby label {
  margin-top: 5px;
  margin-bottom: 0;
  margin-right: 10px;
  color: #111;
  font-size: 14px;
}

.propertu-page-shortby label i {
  margin-right: 5px;
}
.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar-widget:last-child {
  margin-bottom: 0;
}

.service-menu {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li {
  margin-bottom: 5px;
}

.service-menu li:last-child {
  margin-bottom: 0;
}

.service-menu li a {
  display: block;
  padding: 10px 20px;
  background: #fff none repeat scroll 0 0;
  color: #001238;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.service-menu li a span {
  margin-left: 5px;
}

.service-menu li.active a,
.service-menu li a:hover {
  color: #e79e3e;
  padding-left: 25px;
}
.car-grid-list .single-offers {
  text-align: center;
  padding: 20px;
  border: 3px solid #f0f0ff;
  margin-bottom: 30px;
  margin-top: 0;
}

.offer-image {
  width: 235px;
  margin: 0 auto;
  display: block;
}

.offer-image img {
  width: 100%;
}

.offer-text {
  margin-top: 20px;
}

.offer-text h3 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.offer-text h4 {
  color: #020202;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 8px;
}

.offer-text h4 span {
  text-transform: capitalize;
  color: #e79e3e;
  font-size: 16px;
}

.offer-action {
  text-align: center;
  background: #020202 none repeat scroll 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 25px auto 0;
  transition: all 0.4s ease 0s;
}

.offer-action a {
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 15px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  width: 47%;
}

.offer-action:after {
  position: absolute;
  content: "";
  width: 66%;
  height: 100%;
  background: #e79e3e none repeat scroll 0 0;
  right: -36px;
  z-index: -1;
  -webkit-transform: skewX(40deg);
  transform: skewX(40deg);
  transition: all 0.4s ease 0s;
}

.offer-action:hover {
  background: #e79e3e none repeat scroll 0 0;
}

.offer-action:hover:after {
  background: #020202 none repeat scroll 0 0;
}

.offer-text ul {
  text-align: center;
  margin-top: 10px;
}

.offer-text ul li {
  display: inline-block;
  font-size: 14px;
  margin: 0 3px;
}

.offer-text ul li i {
  margin-right: 5px;
  color: #e79e3e;
}
.pagination-box-row {
  text-align: left;
}

.pagination-box-row p {
  display: inline-block;
  font-size: 15px;
  color: #444;
  margin-right: 15px;
}

.pagination {
  margin-top: 20px;
  text-align: center;
  float: none;
  display: inline-block;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination li:last-child {
  margin-right: 0;
}

.pagination li a {
  display: block;
  width: 35px;
  height: 35px;
  font-size: 15px;
  line-height: 35px;
  border-radius: 5px;
  color: #111;
  font-weight: 500;
}

.pagination li.active a,
.pagination li a:hover {
  background: #101010 none repeat scroll 0 0;
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
  .car-listing-right {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .car-listing-right {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .car-listing-right {
    margin-top: 30px;
  }
}

/* ===================== CAR BOOKING ======================*/
.gauto-car-booking {
  background: #fbfbfb none repeat scroll 0 0;
}

.rental-tag {
  display: inline-block;
  padding: 5px 15px;
  line-height: 20px;
  text-transform: uppercase;
  background: #e79e3e;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.gauto-main-slide2 {

  background-position: center center;
  background-size: contain !important;

  height: 640px;
}

.slide2{
  height: 640px;
 
}

.car-booking-right h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.car-booking-right h4 {
  color: #020202;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 0px;
  display: inline-block;
}

.car-booking-right h4 span {
  text-transform: capitalize;
  color: #e79e3e;
  font-size: 16px;
}

.price-rent {
  display: inline-block;
  margin-right: 15px;
}

.car-rating {
  display: inline-block;
}

.car-rating ul {
  display: inline-block;
}

.car-rating ul li {
  display: inline-block;
  margin-right: 1px;
  color: #ffcd00;
}

.car-rating p {
  display: inline-block;
  margin-left: 5px;
  color: #001238;
  text-transform: capitalize;
}

.price-rating {
  margin-bottom: 20px;
}

.car-features ul {
  width: 32%;
  float: left;
  margin-top: 20px;
}

.car-features ul li {
  margin: 5px 0;
}

.car-features ul li svg {
  margin-right: 5px;
  fill: #e79e3e;
}

.single-booking h3,
.booking-right h3 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0ff;
}

.single-booking form label {
  display: block;
  color: #001238;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}

.single-booking form p {
  margin-top: 15px;
}

.single-booking form p input,
.single-booking form p select {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  transition: all 0.4s ease 0s;
}

.single-booking form p textarea {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 120px;
  color: #111;
  transition: all 0.4s ease 0s;
}

.single-booking form p input:focus,
.single-booking form p select:focus {
  border: 2px solid #e79e3e;
}

.single-booking {
  margin-bottom: 30px;
}

.single-booking:last-child {
  margin-bottom: 0px;
}

.gauto-payment .payment {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.gauto-payment .payment input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.gauto-payment .payment label {
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  margin: 10px auto;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  color: #020202;
  font-weight: 500;
}

.gauto-payment .payment .check {
  display: block;
  position: absolute;
  border: 3px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 14px;
  left: 0;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.gauto-payment .payment .check:before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 8px;
  width: 8px;
  top: 3px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .check {
  border: 3px solid #e79e3e;
}

input[type="radio"]:checked ~ .check:before {
  background: #e79e3e;
}

input[type="radio"]:checked ~ label {
  color: #e79e3e;
}

.payment img {
  float: right;
  margin-top: 15px;
}

.action-btn {
  text-align: right;
}

.action-btn a.gauto-btn {
  color: #e79e3e;
  
  margin: 30px 0 0 0;
}

.action-btn a.gauto-btn:hover {
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
  .car-booking-image img {
    width: 100%;
  }
  .car-booking-right {
    margin-top: 30px;
  }
  .booking-right {
    margin-top: 30px;
  }
  .gauto-main-slide2 {
    height: 460px;
  }

  .slide2{
    height: 460px;
    background-color: black;
  }

}

@media (max-width: 767px) {
  .car-booking-right {
    margin-top: 30px;
  }
  .car-booking-right h3,
  .product-details-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .car-features ul {
    width: 50%;
  }
  .booking-right {
    margin-top: 30px;
  }
  .gauto-main-slide2 {
    
    background-position: top !important;
    height: 200px;
  }

  .slide2{
    height: 200px;
    
  
   
   
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .car-booking-right {
    margin-top: 30px;
  }
  .car-booking-right h3,
  .product-details-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .car-features ul {
    width: 33%;
  }
  .booking-right {
    margin-top: 30px;
  }

  .gauto-main-slide2 {
    height: 380px;
  }
}

/* ===================== GALLERY ======================*/
.gauto-gallery-area {
  padding-top: 40px;
  padding-bottom: 60px;
}

.single-gallery {
  position: relative;
  display: block;
  margin-top: 30px;
  z-index: 6;
}

.single-gallery .img-holder {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 5;
}

.single-gallery .img-holder img {
  width: 100%;
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  transition: all 700ms ease;
}

.single-gallery .overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(0, 0, 0, 0.78);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 700ms ease 100ms;
}

.single-gallery .overlay-content .inner-content {
  display: table;
  width: 100%;
  height: 100%;
}

.single-gallery .overlay-content .inner-content .title-box {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: perspective(1200px) rotateX(-90deg) translateZ(-45px);
  transform: perspective(1200px) rotateX(-90deg) translateZ(-45px);
  transition: all 300ms ease 100ms;
}

.title-box h3 {
  font-size: 24px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 5px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.single-gallery .overlay-content .inner-content .title-box h3 a {
  color: #ffffff;
  transition: all 500ms ease;
}

.single-gallery .img-holder .link-zoom-button {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  text-align: center;
  display: block;
  background: transparent;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: perspective(1200px) rotateX(90deg) translateZ(-45px);
  transform: perspective(1200px) rotateX(90deg) translateZ(-45px);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition: all 300ms ease 200ms;
  z-index: 3;
}

.single-gallery .img-holder .link-zoom-button .single-button {
  position: relative;
  display: block;
  width: 50%;
  float: left;
}

.single-gallery .img-holder .link-zoom-button .single-button a {
  position: relative;
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 60px;
  font-weight: 500;
  background: #e79e3e;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  transition: all 500ms ease;
}

.single-gallery .img-holder .link-zoom-button .single-button {
  position: relative;
  display: block;
  width: 50%;
  float: left;
}

.single-gallery .img-holder .link-zoom-button .single-button a.zoom {
  color: #ffffff;
  background: #131313;
}

.single-gallery:hover .img-holder img {
  -webkit-transform: scale(1.2) rotate(2deg);
  transform: scale(1.2) rotate(2deg);
}

.single-gallery:hover .overlay-content {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.single-gallery:hover .overlay-content .inner-content .title-box {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  transition: all 700ms ease 500ms;
}

.single-button a span {
  margin-right: 5px;
}

.single-gallery:hover .img-holder .link-zoom-button {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transition: all 700ms ease 800ms;
}

/* ===================== PRODUCTS ======================*/
.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar-widget:last-child {
  margin-bottom: 0;
}

.service-menu {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li {
  margin-bottom: 5px;
}

.service-menu li:last-child {
  margin-bottom: 0;
}

.service-menu li a {
  display: block;
  padding: 10px 20px;
  background: #fff none repeat scroll 0 0;
  color: #001238;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.service-menu li a span {
  margin-left: 5px;
}

.service-menu li.active a,
.service-menu li a:hover {
  color: #e79e3e;
  padding-left: 25px;
}
.product_search {
  width: 100%;
  background: #f4f4f5 none repeat scroll 0 0 !important;
  padding: 0 !important;
  height: 50px;
  position: relative;
  border-radius: 5px 0 0 5px;
}

.product_search input {
  position: absolute;
  width: 100%;
  height: 100%;
  border: medium none;
  padding: 5px 15px;
  background: transparent;
}

.product_search button {
  position: absolute;
  right: 0;
  border: medium none;
  height: 100%;
  width: 20%;
  color: #fff;
  cursor: pointer;
  background: #001238 none repeat scroll 0 0;
  font-size: 15px;
  border-radius: 0 5px 5px 0px;
}

.product-page-left .sidebar-widget h3 {
  font-size: 18px;
}

.top-products {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.top-products li {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  background: #fff none repeat scroll 0 0;
  overflow: hidden;
}

.top-products li:last-child {
  border-bottom: 0px solid #ddd;
}

.recent-img {
  margin-right: 15px;
  width: 80px;
  float: left;
}

.recent-text {
  vertical-align: middle;
  overflow: hidden;
}

.recent-text h4 {
  font-size: 17px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.recent-text h4 a {
  color: #001238;
}

.recent-text p,
.product-title p {
  color: #e79e3e;
  font-weight: 500;
}

.product-image img {
  width: 100%;
}

.product-text {
  padding-top: 15px;
}

.product-text h3 {
  font-size: 18px;
  color: #001238;
  letter-spacing: 1px;
  margin: 5px 0;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.product-text h3 a {
  color: #001238;
}

.product-text {
  padding-top: 15px;
  display: flex;
  align-content: center;
  align-items: center;
}

.product-action {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

.product-action a {
  width: 45px;
  height: 45px;
  text-align: center;
  border: 1px solid #ddd;
  line-height: 44px;
  font-size: 16px;
  color: #333;
  border-radius: 5px;
}

.product-action a:hover {
  background: #e79e3e none repeat scroll 0 0;
  color: #fff;
  border: 1px solid #e79e3e;
}

.product-item {
  margin-bottom: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .product-page-right {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .product-page-right,
  .product-details-text {
    margin-top: 30px;
  }
  .product-text h3 {
    font-size: 21px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .product-page-right,
  .product-details-text {
    margin-top: 30px;
  }
  .product-text h3 {
    font-size: 21px;
  }
}

/* ===================== PRODUCT DETAILS ======================*/
.product-details-image {
  text-align: center;
}

.product-details-image img {
  width: 100%;
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.product-details-text h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.single-pro-page-para {
  margin: 20px 0;
}

.single-shop-price > p {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  margin-top: 15px;
}

.single-shop-price > p {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  margin-top: 15px;
}

.single-shop-price > p input {
  border: 1px solid #ddd;
  font-weight: 500;
  margin-left: 20px;
  padding: 5px 10px;
  width: 80px;
}

.single-shop-price p span {
  color: #e79e3e;
}

.single-shop-page-btn a.gauto-btn {
  color: #e79e3e;
  margin: 0;
}

.single-shop-page-btn a.gauto-btn:hover {
  color: #fff;
}

.single-shop-page-btn {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 30px;
}

.single-shop-page-btn ul {
  margin-left: 30px;
}

.single-shop-page-btn ul li {
  display: inline-block;
  margin: 0 2px;
}

.single-shop-page-btn ul li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #333;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.single-shop-page-btn ul li a:hover,
.gauto-cart-close a:hover {
  background: #e79e3e none repeat scroll 0 0;
  color: #fff;
  border: 1px solid #e79e3e;
}

.gauto-related-products .product-item {
  margin-top: 30px;
  margin-bottom: 0;
}

/* ===================== CART ======================*/
.cart-table-left h3,
.order-summury-box h3 {
  font-size: 22px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.cart-table-left thead {
  background: #f4f4f5 none repeat scroll 0 0;
  border: medium none;
}

.cart-table-left thead th {
  border: medium none;
  color: #001238;
}

.gauto-cart-preview {
  width: 115px;
  height: 100px;
  text-align: center;
}

.gauto-cart-preview img {
  width: 100%;
  height: 100%;
}

.table th,
.table td {
  vertical-align: baseline;
  border-top: 0px;
  border-bottom: 1px solid #f0f0ff;
}

.cart-table-left table td p {
  color: #001238;
  text-transform: capitalize;
}

.gauto-cart-quantity > input {
  border: 1px solid #ddd;
  padding: 5px;
  width: 70px;
}

.gauto-cart-close {
  text-align: center;
}

.gauto-cart-total {
  font-weight: 500;
}

.gauto-cart-close a {
  color: #333;
  width: 35px;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.cart-clear a {
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
  color: #001238;
  margin-right: 15px;
}

.cart-clear {
  margin-top: 20px;
}

.cart-clear a:last-child {
  margin-right: 1px;
}

.cart-clear a:hover {
  color: #e79e3e;
}

.order-summury-box {
  padding: 30px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.order-summury-box table {
  width: 100%;
}

.order-summury-box table tr {
  border-bottom: 1px solid #ddd;
}

.order-summury-box table tr:last-child {
  border-bottom: 0px solid #ddd;
}

.order-summury-box table td {
  padding: 10px 0;
  color: #001238;
}

.checkout-action {
  text-align: right;
  margin-top: 30px;
}

.checkout-action a.gauto-btn {
  color: #e79e3e;
  margin: 0;
}

.checkout-action a.gauto-btn:hover {
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
  .order-summury-box {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .order-summury-box {
    margin-top: 30px;
  }
  .checkout-action {
    text-align: left;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .order-summury-box {
    margin-top: 30px;
  }
  .checkout-action {
    text-align: left;
  }
}

/* ===================== CHECKOUT ======================*/
.checkout-left-box h3 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0ff;
}

.checkout-left-box form .checkout-form {
  margin-top: 15px;
}

.checkout-left-box form input {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  transition: all 0.4s ease 0s;
}

.checkout-left-box form textarea {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 135px;
  color: #111;
  transition: all 0.4s ease 0s;
}

.checkout-left-box form input:focus {
  border: 2px solid #e79e3e;
}

.checkout-left-box form label {
  color: #001238;
}

/* ===================== BLOG SIDEBAR ======================*/
.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar-widget:last-child {
  margin-bottom: 0;
}

.service-menu {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li {
  margin-bottom: 5px;
}

.service-menu li:last-child {
  margin-bottom: 0;
}

.service-menu li a {
  display: block;
  padding: 10px 20px;
  background: #fff none repeat scroll 0 0;
  color: #001238;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.service-menu li a span {
  margin-left: 5px;
}

.service-menu li.active a,
.service-menu li a:hover {
  color: #e79e3e;
  padding-left: 25px;
}
.service-details-right h3,
.sidebar-widget h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.service-details-right p {
  margin: 0 0 10px;
}

.pad-right-sm {
  padding-right: 7px;
}

.pad-left-sm {
  padding-left: 7px;
}

.service-details-image {
  margin-top: 10px;
}

.service-details-list ul {
  width: 50%;
  float: left;
  margin-top: 20px;
}

.service-details-list li {
  margin: 5px 0;
}

.service-details-list li i {
  color: #e79e3e;
  margin-right: 5px;
}

.service-accordion {
  margin-top: 20px;
}

.service-accordion h3,
.sidebar-widget h3 {
  font-size: 20px;
}

.Tags-catagory {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.Tags-catagory li {
  display: inline-block;
  border: medium none !important;
  padding: 0 !important;
  margin: 2px;
}

.Tags-catagory a {
  color: #001238;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  padding: 5px 15px;
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
}

.Tags-catagory a:hover {
  color: #e79e3e;
}

.blog-text ul li svg {
  margin-right: 5px;
  fill: #e79e3e;
}
.blog-page-left .pagination {
  margin-top: 50px;
}

/* ===================== BLOG LIST ======================*/
.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar-widget:last-child {
  margin-bottom: 0;
}

.service-menu {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li {
  margin-bottom: 5px;
}

.service-menu li:last-child {
  margin-bottom: 0;
}

.service-menu li a {
  display: block;
  padding: 10px 20px;
  background: #fff none repeat scroll 0 0;
  color: #001238;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.service-menu li a span {
  margin-left: 5px;
}

.service-menu li.active a,
.service-menu li a:hover {
  color: #e79e3e;
  padding-left: 25px;
}
.service-details-right h3,
.sidebar-widget h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.service-details-right p {
  margin: 0 0 10px;
}

.pad-right-sm {
  padding-right: 7px;
}

.pad-left-sm {
  padding-left: 7px;
}

.service-details-image {
  margin-top: 10px;
}

.service-details-list ul {
  width: 50%;
  float: left;
  margin-top: 20px;
}

.service-details-list li {
  margin: 5px 0;
}

.service-details-list li i {
  color: #e79e3e;
  margin-right: 5px;
}

.service-accordion {
  margin-top: 20px;
}

.service-accordion h3,
.sidebar-widget h3 {
  font-size: 20px;
}

.Tags-catagory {
  padding: 15px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.Tags-catagory li {
  display: inline-block;
  border: medium none !important;
  padding: 0 !important;
  margin: 2px;
}

.Tags-catagory a {
  color: #001238;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  padding: 5px 15px;
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
}

.Tags-catagory a:hover {
  color: #e79e3e;
}

.blog-text ul li svg {
  margin-right: 5px;
  fill: #e79e3e;
}
.blog-page-left .pagination {
  margin-top: 50px;
}

/* ===================== BLOG SINGLE ======================*/
.single-blog-page .blog-text {
  padding: 30px 0 0 0;
}

.single-blog-page .blog-text p {
  margin: 0 0 10px;
}

.single-blog-page .single-blog {
  border: 0px solid #f0f0ff;
}

.blog-content-img {
  margin-bottom: 15px;
}

.single-blog-page blockquote {
  margin: 30px 0;
  position: relative;
}

.single-blog-page blockquote .quote-inner {
  border-left: 2px solid #ddd;
  margin-left: 25px;
  padding-left: 60px;
  position: relative;
}

.single-blog-page blockquote .quote-icon {
  background-color: #ffffff;
  border: 2px solid #e9f2f7;
  border-radius: 50%;
  color: #d2dae0;
  font-size: 16px;
  height: 35px;
  left: -18px;
  line-height: 32px;
  margin-top: -17px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 35px;
  padding: 7px;
}

.single-blog-page blockquote .quote-text {
  color: #001238;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  position: relative;
}

.gauto-comment-list,
.gauto-leave-comment {
  margin-top: 50px;
}

.gauto-comment-list h2,
.gauto-leave-comment h2 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.single-comment-box {
  margin-bottom: 10px;
  position: relative;
  transition: all 300ms ease 0s;
}

.main-comment {
  font-size: 14px;
  padding: 30px 0 0 90px;
  position: relative;
}

.author-image {
  border-radius: 50%;
  left: 0;
  margin-bottom: 20px;
  position: absolute;
  top: 30px;
}

.author-image img {
  display: block;
  width: 70px;
  border-radius: 50%;
  border: 6px solid #e6e6e6;
}

.comment-info h4 {
  font-size: 16px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.comment-info a {
  color: #001238;
  font-style: italic;
}

.comment-info a:hover {
  color: #e79e3e;
}

.comment-info a i {
  margin-right: 5px;
}

.single-comment-box.reply-comment {
  padding-left: 100px;
}

.gauto-leave-comment form input {
  width: 45%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  transition: all 0.4s ease 0s;
  margin: 10px 15px 10px 0px;
}

.gauto-leave-comment form textarea {
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  margin: 10px 0;
  color: #111;
  width: 93%;
  height: 175px;
  transition: all 0.4s ease 0s;
}

.gauto-leave-comment form input:focus,
.gauto-leave-comment form textarea:focus {
  border: 2px solid #e79e3e;
}

.gauto-leave-comment .gauto-theme-btn {
  width: auto;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .single-comment-box.reply-comment {
    padding-left: 30px;
  }
  .gauto-leave-comment form input {
    width: 100%;
    margin-right: 0;
  }
  .gauto-leave-comment form textarea {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .blog-text {
    padding: 15px 10px;
  }
  .blog-text ul li {
    margin-right: 5px;
  }
  .blog-page-left .blog-text h3 {
    font-size: 22px;
    line-height: 36px;
  }
  .blog-page-right {
    margin-top: 30px;
  }
  .single-comment-box.reply-comment {
    padding-left: 30px;
  }
  .gauto-leave-comment form input {
    width: 100%;
    margin-right: 0;
  }
  .gauto-leave-comment form textarea {
    width: 100%;
  }
}

/* ===================== ERROR ======================*/
.notfound-box {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.notfound-box h2 {
  font-size: 130px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 130px;
}

.notfound-box h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 40px;
}

.notfound-box a.gauto-btn {
  margin: 30px 0 0 0;
  color: #e79e3e;
}

.notfound-box a.gauto-btn:hover {
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
  .notfound-box {
    width: 80%;
  }
  .notfound-box h2 {
    font-size: 90px;
    line-height: 90px;
  }
  .notfound-box h3 {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .notfound-box {
    width: 100%;
  }
  .notfound-box h2 {
    font-size: 70px;
    line-height: 70px;
  }
  .notfound-box h3 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .notfound-box {
    width: 100%;
  }
  .notfound-box h2 {
    font-size: 70px;
    line-height: 70px;
  }
  .notfound-box h3 {
    font-size: 22px;
  }
}

/* ===================== LOGIN ======================*/
.gauto-login-area {
  background: #fbfbfd none repeat scroll 0 0;
}

.login-box {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0px 3px 14px 0px rgba(0, 11, 40, 0.06);
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  width: 40%;
}

.login-page-heading {
  padding-bottom: 20px;
}

.login-page-heading svg {
  font-size: 22px;
  margin-right: 5px;
  color: #e79e3e;
}

.login-page-heading > h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #001238;
  font-size: 25px;
  text-transform: capitalize;
  margin-bottom: 5px;
  display: inline-block;
}

.login-page-box > form {
  margin-top: 30px;
}

.account-form-group {
  height: 45px;
  margin-bottom: 15px;
  position: relative;
}

.account-form-group > input {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px 5px 60px;
  height: 45px;
  color: #111;
  transition: all 0.4s ease 0s;
}

.account-form-group svg {
  border-right: 2px solid #f0f0ff;
  color: #b7b7b7;
  font-size: 16px;
  height: 100%;
  left: 0;
  line-height: 45px;
  margin-right: 5px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 48px;
  transition: all 0.4s ease 0s;
  padding: 15px;
}

.login-box button.gauto-theme-btn {
  margin: 20px 0 0 0;
  width: auto;
  display: inline-block;
}

.login-page-box button:hover {
  background: #212121 none repeat scroll 0 0;
}

.login-sign-up {
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid #f0f0ff;
}

.login-sign-up > a {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  color: #001238;
}

.login-sign-up > a:hover {
  color: #e79e3e;
}

.login-page-box .remember {
  margin-left: 0 !important;
}

.remember-row {
  display: flex;
  align-content: center;
  align-items: center;
}

.login-meta p {
  flex: 1 1;
  display: flex;
}

.remember-row .checkbox {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
}

.remember-row .checkbox.signup {
  display: flex;
  justify-content: center;
  flex: 1 1;
}

.lost-pass a {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  color: #001238;
  margin-right: 15px;
}

.lost-pass a:hover {
  color: #e79e3e;
}

.checkbox label {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  color: #001238;
}

@-webkit-keyframes pull {
  0% {
    height: 0;
  }
  100% {
    height: 1.2rem;
  }
}

@keyframes pull {
  0% {
    height: 0;
  }
  100% {
    height: 1.2rem;
  }
}

@-webkit-keyframes pop {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  75% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes pop {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  75% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.checkbox-spin {
  display: none;
}

.checkbox-spin + label span {
  display: inline-block;
  width: 25px;
  height: 16px;
  margin: 0 10px -3px 0;
}

.checkbox-spin + label span:before,
.checkbox-spin + label span:after {
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  z-index: 1;
  width: 16px;
  width: 16px;
  width: 16px;
  width: 1rem;
  height: 16px;
  height: 16px;
  height: 16px;
  height: 1rem;
  background: #fff none repeat scroll 0 0;
  border: 2px solid #dbdbdb;
}

.checkbox-spin + label span:after {
  z-index: 0;
  border: none;
}

.checkbox-spin:checked + label span:before {
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
  background: #e79e3e;
  border: 1px solid #e79e3e;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .login-box {
    width: 55%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .login-box {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .login-box {
    width: 100%;
    padding: 30px 15px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .login-box {
    width: 100%;
    padding: 30px 15px;
  }
}

/* ===================== REGISTER ======================*/
.gauto-login-area {
  background: #fbfbfd none repeat scroll 0 0;
}

.login-box {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0px 3px 14px 0px rgba(0, 11, 40, 0.06);
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  width: 40%;
}

.login-page-heading {
  padding-bottom: 20px;
}

.login-page-heading svg {
  font-size: 22px;
  margin-right: 5px;
  color: #e79e3e;
}

.login-page-heading > h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #001238;
  font-size: 25px;
  text-transform: capitalize;
  margin-bottom: 5px;
  display: inline-block;
}

.login-page-box > form {
  margin-top: 30px;
}

.account-form-group {
  height: 45px;
  margin-bottom: 15px;
  position: relative;
}

.account-form-group > input {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px 5px 60px;
  height: 45px;
  color: #111;
  transition: all 0.4s ease 0s;
}

.account-form-group svg {
  border-right: 2px solid #f0f0ff;
  color: #b7b7b7;
  font-size: 16px;
  height: 100%;
  left: 0;
  line-height: 45px;
  margin-right: 5px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 48px;
  transition: all 0.4s ease 0s;
  padding: 15px;
}

.login-box button.gauto-theme-btn {
  margin: 20px 0 0 0;
  width: auto;
  display: inline-block;
}

.login-page-box button:hover {
  background: #212121 none repeat scroll 0 0;
}

.login-sign-up {
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid #f0f0ff;
}

.login-sign-up > a {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  color: #001238;
}

.login-sign-up > a:hover {
  color: #e79e3e;
}

.login-page-box .remember {
  margin-left: 0 !important;
}

.remember-row {
  display: flex;
  align-content: center;
  align-items: center;
}

.login-meta p {
  flex: 1 1;
  display: flex;
}

.remember-row .checkbox {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
}

.remember-row .checkbox.signup {
  display: flex;
  justify-content: center;
  flex: 1 1;
}

.lost-pass a {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  color: #001238;
  margin-right: 15px;
}

.lost-pass a:hover {
  color: #e79e3e;
}

.checkbox label {
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  color: #001238;
}

@-webkit-keyframes pull {
  0% {
    height: 0;
  }
  100% {
    height: 1.2rem;
  }
}

@keyframes pull {
  0% {
    height: 0;
  }
  100% {
    height: 1.2rem;
  }
}

@-webkit-keyframes pop {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  75% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes pop {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  75% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.checkbox-spin {
  display: none;
}

.checkbox-spin + label span {
  display: inline-block;
  width: 25px;
  height: 16px;
  margin: 0 10px -3px 0;
}

.checkbox-spin + label span:before,
.checkbox-spin + label span:after {
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  z-index: 1;
  width: 16px;
  width: 16px;
  width: 16px;
  width: 1rem;
  height: 16px;
  height: 16px;
  height: 16px;
  height: 1rem;
  background: #fff none repeat scroll 0 0;
  border: 2px solid #dbdbdb;
}

.checkbox-spin + label span:after {
  z-index: 0;
  border: none;
}

.checkbox-spin:checked + label span:before {
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
  background: #e79e3e;
  border: 1px solid #e79e3e;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .login-box {
    width: 55%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .login-box {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .login-box {
    width: 100%;
    padding: 30px 15px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .login-box {
    width: 100%;
    padding: 30px 15px;
  }
}

/* ===================== CONTACT ======================*/
.contact-left h3,
.contact-right h3 {
  font-size: 24px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.contact-left form input {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  transition: all 0.4s ease 0s;
  margin: 10px 15px 10px 0px;
}

.contact-left form textarea {
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  margin: 10px 0;
  color: #111;
  width: 100%;
  height: 135px;
  transition: all 0.4s ease 0s;
}

.contact-left form input:focus,
.contact-left form textarea:focus {
  border: 2px solid #e79e3e;
}

.single-contact-field button.gauto-theme-btn {
  width: auto;
  display: inline-block;
  margin-top: 20px;
}

.contact-details p i {
  color: #e79e3e;
  margin-right: 5px;
}

.single-contact-btn {
  display: inline-block;
  margin-right: 10px;
  margin-top: 25px;
}

.single-contact-btn h4,
.social-links-contact h4 {
  font-size: 16px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.single-contact-btn a {
  display: inline-block;
  padding: 10px 16px;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  background: #e79e3e none repeat scroll 0 0;
  border-radius: 4px;
}

.single-contact-btn a:hover {
  background: #001238 none repeat scroll 0 0;
}

.social-links-contact {
  margin-top: 30px;
}

.social-links-contact ul li {
  display: inline-block;
  margin: 0 2px;
}

.social-links-contact ul li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #333;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.social-links-contact ul li a:hover {
  background: #e79e3e none repeat scroll 0 0;
  color: #fff;
  border: 1px solid #e79e3e;
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-right {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .contact-right {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .contact-right {
    margin-top: 30px;
  }
}

/* ===================== CAR BOOKING ======================*/
.gauto-car-booking {
  background: #fbfbfb none repeat scroll 0 0;
}

.rental-tag {
  display: inline-block;
  padding: 5px 15px;
  line-height: 20px;
  text-transform: uppercase;
  background: #e79e3e;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.gauto-main-slide2 {

  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 640px;
}

.car-booking-right h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.car-booking-right h4 {
  color: #020202;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 0px;
  display: inline-block;
}

.car-booking-right h4 span {
  text-transform: capitalize;
  color: #e79e3e;
  font-size: 16px;
}

.price-rent {
  display: inline-block;
  margin-right: 15px;
}

.car-rating {
  display: inline-block;
}

.car-rating ul {
  display: inline-block;
}

.car-rating ul li {
  display: inline-block;
  margin-right: 1px;
  color: #ffcd00;
}

.car-rating p {
  display: inline-block;
  margin-left: 5px;
  color: #001238;
  text-transform: capitalize;
}

.price-rating {
  margin-bottom: 20px;
}

.car-features ul {
  width: 32%;
  float: left;
  margin-top: 20px;
}

.car-features ul li {
  margin: 5px 0;
}

.car-features ul li svg {
  margin-right: 5px;
  fill: #e79e3e;
}

.single-booking h3,
.booking-right h3 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0ff;
}

.single-booking form label {
  display: block;
  color: #001238;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}

.single-booking form p {
  margin-top: 15px;
}

.single-booking form p input,
.single-booking form p select {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  transition: all 0.4s ease 0s;
}

.single-booking form p textarea {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 120px;
  color: #111;
  transition: all 0.4s ease 0s;
}

.single-booking form p input:focus,
.single-booking form p select:focus {
  border: 2px solid #e79e3e;
}

.single-booking {
  margin-bottom: 30px;
}

.single-booking:last-child {
  margin-bottom: 0px;
}

.gauto-payment .payment {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.gauto-payment .payment input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.gauto-payment .payment label {
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  margin: 10px auto;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  color: #020202;
  font-weight: 500;
}

.gauto-payment .payment .check {
  display: block;
  position: absolute;
  border: 3px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 14px;
  left: 0;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.gauto-payment .payment .check:before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 8px;
  width: 8px;
  top: 3px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .check {
  border: 3px solid #e79e3e;
}

input[type="radio"]:checked ~ .check:before {
  background: #e79e3e;
}

input[type="radio"]:checked ~ label {
  color: #e79e3e;
}

.payment img {
  float: right;
  margin-top: 15px;
}

.action-btn {
  text-align: right;
}

.action-btn a.gauto-btn {
  color: #e79e3e;
  margin: 30px 0 0 0;
}

.action-btn a.gauto-btn:hover {
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
  .car-booking-image img {
    width: 100%;
  }
  .car-booking-right {
    margin-top: 30px;
  }
  .booking-right {
    margin-top: 30px;
  }
  .gauto-main-slide2 {
    height: 460px;
  }

}

@media (max-width: 767px) {
  .car-booking-right {
    margin-top: 30px;
  }
  .car-booking-right h3,
  .product-details-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .car-features ul {
    width: 50%;
  }
  .booking-right {
    margin-top: 30px;
  }
  .gauto-main-slide2 {
    height: 380px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .car-booking-right {
    margin-top: 30px;
  }
  .car-booking-right h3,
  .product-details-text h3 {
    font-size: 28px;
    line-height: 40px;
  }
  .car-features ul {
    width: 33%;
  }
  .booking-right {
    margin-top: 30px;
  }

  .gauto-main-slide2 {
    height: 380px;
  }
}

/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/

body {
  font-size: 14px;
  line-height: 26px;
  font-style: normal;
  color: #7c8a97;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0;
}

.no-pad-left {
  padding-left: 0;
}

.no-pad-right {
  padding-right: 0;
}

.no-pad-all {
  padding: 0;
}

.fix {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  transition: all 0.4s ease 0s;
  color: #6b739c;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
  outline: medium none;
  color: #6b739c;
}

a:hover {
  color: inherit;
  text-decoration: none;
  color: inherit;
}

input:focus,
textarea:focus,
button:focus,
select:focus {
  outline: medium none;
}

:-moz-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

::-moz-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

:-ms-input-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

::-webkit-input-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

:-ms-select-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

::-webkit-select-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

:-ms-textarea-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

::-webkit-textarea-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.gauto-btn,
button.gauto-theme-btn {
  color: #fff;
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #e79e3e;
  position: relative;
  margin: 1em;
  display: inline-block;
  padding: 8px 15px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gauto-btn:before,
.gauto-btn:after,
button.gauto-theme-btn:before,
button.gauto-theme-btn:after {
  content: "";
  display: block;
  position: absolute;
  border-color: #e79e3e;
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  transition: all 0.3s ease-in-out;
}

.gauto-btn:before,
button.gauto-theme-btn:before {
  top: -6px;
  left: -6px;
  border-width: 2px 0 0 2px;
  z-index: 5;
}

.gauto-btn:after,
button.gauto-theme-btn:after {
  bottom: -6px;
  right: -6px;
  border-width: 0 2px 2px 0;
}

.gauto-btn:hover:before,
.gauto-btn:hover:after,
button.gauto-theme-btn:hover:before,
button.gauto-theme-btn:hover:after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: #e79e3e;
}

.gauto-btn:hover,
button.gauto-theme-btn:hover {
  color: #fff;
  background-color: #e79e3e;
  border-color: #e79e3e;
}

button.gauto-theme-btn {
  color: #e79e3e;
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #e79e3e;
  position: relative;
  display: block;
  padding: 8px 15px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  margin: 0;
  width: 100%;
}

button.gauto-theme-btn:before,
button.gauto-theme-btn:after {
  content: "";
  display: block;
  position: absolute;
  border-color: #e79e3e;
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  transition: all 0.3s ease-in-out;
}

.gauto-btn:before,
button.gauto-theme-btn:before {
  top: -6px;
  left: -6px;
  border-width: 2px 0 0 2px;
  z-index: 5;
}

.gauto-btn:after,
button.gauto-theme-btn:after {
  bottom: -6px;
  right: -6px;
  border-width: 0 2px 2px 0;
}

button.gauto-theme-btn:hover:before,
button.gauto-theme-btn:hover:after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: #e79e3e;
}

.gauto-btn:hover,
button.gauto-theme-btn:hover {
  color: #fff;
  background-color: #e79e3e;
  border-color: #e79e3e;
}

.site-heading {
  margin-bottom: 30px;
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.site-heading h4 {
  font-size: 20px;
  color: #e79e3e;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Rubik", sans-serif;
}

.site-heading h2 {
  font-size: 40px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.section_100 {
  padding: 100px 0;
}

.section_70 {
  padding: 70px 0;
}

.section_50 {
  padding: 50px 0;
}

.section_15 {
  padding: 15px 0;
}

.pad-right {
  padding-right: 0;
}

.section_t_100 {
  padding-top: 100px;
}

.section_b_70 {
  padding-bottom: 70px;
}

.section_70 {
  padding: 70px 0;
}

.section_b_80 {
  padding-bottom: 80px;
}

.btntoTop:before {
  color: #fff;
  content: "\EF\201E\201A";
  display: inline-block;
  font: 20px "FontAwesome";
  vertical-align: middle;
}

.btntoTop {
  background-color: #e79e3e;
  border: 2px solid #e79e3e;
  border-radius: 5px;
  bottom: 70px;
  cursor: pointer;
  height: 50px;
  line-height: 47px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: fixed;
  right: 20px;
  text-align: center;
  transition: opacity 0.4s ease 0s;
  width: 50px;
  z-index: 99999;
}

.btntoTop.active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transition: all 0.4s ease 0s;
}

.btntoTop.active:hover {
  background: #212121 none repeat scroll 0 0;
  border: 2px solid #cb2415;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

.btntoTop.active.btntoTop:hover:before {
  color: #fff;
}

@media (max-width: 767px) {
  .site-heading {
    width: 100%;
  }
  .site-heading h2 {
    font-size: 30px;
  }
  .pad-right-sm {
    padding-right: 15px;
  }
  .pad-left-sm {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .site-heading {
    width: 100%;
  }
  .site-heading h2 {
    font-size: 30px;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}


